import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';
import { Recorrido, RecorridoTrad } from '@app/_models/recorrido';
import { RecorridosEnteService } from '@app/_services/recorridos-ente.service';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
@Component({
  selector: 'app-recorridos-crear',
  templateUrl: './recorridos-crear.component.html',
})
export class RecorridosCrearComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaTraduccionesEnviar: RecorridoTrad[] = [];
  //Data
  myRecorrido: Recorrido = new Recorrido();
  nombre: string = '';
  descripcion: string = '';
  distanciaKm:number=0;
  idOrg: string = '0a0a0a0a-a4cb-4e0a-97b7-1784c9735130';
  selectedIdioma: Idioma;
  filePath: string;

  gpxFileName;
  gpxContent;

  lang;
  private translate: TranslateService;

  public restrictionsGpx: FileRestrictions = {
    allowedExtensions: ['.xml', '.gpx'],
    maxFileSize: 1048576,
  };

  constructor(
    private recorridosService: RecorridosEnteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('global.recorridos');
    this.loadIdiomas();
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new RecorridoTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });
  }

  loadIdiomas() {
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }

  btnCrearRecorrido() {
    console.log('Creando Recorrido...');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.warn(
        this.translateService.instant('eventos.errortrads')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: RecorridoTrad = new RecorridoTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.descripcion = seleccionado.descripcion;
      }
    });

    this.myRecorrido.distanciaKm = this.distanciaKm;
    this.myRecorrido.urlGpxFile = this.gpxContent;//Data
    this.myRecorrido.idOrg = this.idOrg;
    this.myRecorrido.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base64
    this.myRecorrido.listaTraducciones = this.listaTraduccionesEnviar;
    console.log(this.myRecorrido);
 
    this.recorridosService.insert(this.myRecorrido).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('eventos.success')
        );
        this.router.navigate(['recorridos-ente']);
      } else {
        this.alertService.error(this.translateService.instant('eventos.error'));
        return;
      }
    });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombre.length <= 0 ||
      this.descripcion.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('eventos.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombre;
          x.descripcion = this.descripcion;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }



  btnCancelar() {
    this.router.navigate(['recorridos-ente']);
  }

  getIdiomaFromReus(idioma) {
    //console.log('Llega Idioma');
    //console.log(idioma);
    this.selectedIdioma = idioma;
  }

//FILES

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  public selectEventHandlerGpx(e: SelectEvent): void {
    const that = this;

    e.files.forEach((fileGpx) => {
      if (!fileGpx.validationErrors) {
        const readerGpx = new FileReader();

        readerGpx.onload = function (ev) {
          const gpx = {
            src: ev.target['result'],
            uid: fileGpx.uid,
          };
          that.gpxFileName = fileGpx.name;
          that.gpxContent = gpx.src;
        };
        readerGpx.readAsText(fileGpx.rawFile);
      }
    });
  }
}
