import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { not } from '@angular/compiler/src/output/output_ast';
import { Categoria } from '@app/_models/categoria';
import { Usuario } from '@app/_models';

const baseUrl = `${environment.apiUrl}/noticia`;

@Injectable()
export class PublicacionesService {
  constructor(private http: HttpClient) {}

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    ); //idUser no necesario
  }

  public getPublicacionesFromEnte(idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/ente/' + idOrg);
  }

  public getPublicacionWithLang(
    idNoticia: number,
    lang: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/get/' + idNoticia + '/' + lang);
  }

  public loadCategorias(ididioma: string, idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/categorias/' + idOrg + '/' + ididioma);
  }
  public getCategoriaById(idCategoria: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/categorias/get/' + idCategoria + '/' + lang);
  }

  public createPublicacion(noticia: Noticia): Observable<any> {
    if(noticia.programarPublicacion)
    noticia.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      baseUrl + '/createw/',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public actualizarPublicacion(noticia: Noticia): Observable<any> {
    if(noticia.programarPublicacion)
    noticia.fecha = new Date(this.dateToYYYYMMDDtHHmmSSz(noticia.fecha));
    return this.http.post(
      baseUrl + '/updateweb',
      JSON.stringify(noticia),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  public addTraduccionesNoticia(listaTraducciones, idNoticia): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/addtradsw/' + idNoticia.toString(),
      JSON.stringify(listaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public crearCategoria(
    listaCategoriaTraducciones,
    idOrg: string
  ): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/categorias/crear/' + idOrg.toString(),
      JSON.stringify(listaCategoriaTraducciones),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarCategoria(myCategoria: Categoria): Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(
      baseUrl + '/categorias/update',
      JSON.stringify(myCategoria),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  deleteCategorias(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarcategorias', formData, {
      withCredentials: true,
    });
  }

  //SORTEOS
  public getUsuariosLikedPublicacion(idPost:number): Observable<any> {
    return this.http.get(baseUrl + '/getusersliked/' + idPost );
  }

  public asignarGanadoresSorteoNoticia(
    listaGanadores:Usuario[],
    idpost: number
  ): Observable<any> {

    return this.http.post(
      baseUrl + '/createganadores/' + idpost,
      JSON.stringify(listaGanadores),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getGanadores(
    idNoticia: number
  ): Observable<any> {
    return this.http.get(baseUrl + '/getganadores/' + idNoticia);
  }
  

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }
}
