import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';

@Component({
  selector: 'app-organizaciones',
  templateUrl: './organizaciones.component.html',
})
export class OrganizacionesComponent implements OnInit {

  public dataOrganizaciones: [];
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private organizacionesService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('organizaciones.titulo');
    this.organizacionesService.GetAll().subscribe((result) => {
      this.dataOrganizaciones = result.data;
    })
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.organizacionesService.GetAll().subscribe((result) => {
      this.dataOrganizaciones = result.data;
      console.log('dataOrganizaciones Loaded :'+this.dataOrganizaciones.length.toString())
    });
  }

  onClickNuevo() {
    this.router.navigate(['organizaciones/crear/']);
  }

  onClickEditar() {
    console.log('EDITAR PULSADO');
    console.log(this.seleccionados[0]);
    if (this.seleccionados.length>0) {
      this.router.navigate(['organizaciones/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['organizaciones/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.organizacionesService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.organizacionesService.GetAll().subscribe((result) => {
            this.dataOrganizaciones = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }
}
