export class CategoriaEcommerce {
  id: number;
  idOrg: string;
  idIdioma: number;
  nombre: string;
  descripcion: string;
  urlFoto: string;
  color: string;
  isRedirect:boolean;
  urlRedirect:string;
  activa: boolean;
  created: Date;
  updated: Date;
  updateFoto:boolean;
  listaTraducciones: CategoriaEcommerceTrad[];
}

export class CategoriaEcommerceTrad {
  id: number;
  nombre: string;
  descripcion: string;
  idCategoria: number;
  idIdioma: number;
  nombreIdioma: string;
  filled: boolean;
}

export class Articulo {
  id: number;
  idOrg: string;
  idIdioma: number;
  nombre: string;
  descripcion: string;
  precio:string;
  stock: number;
  isOferta: boolean;
  porcentajeOferta: string;
  urlFoto: string;
  idCategoria: number;
  isRedirect:boolean;
  urlRedirect:string;
  activo: boolean;
  created: Date;
  updated: Date;
  updateFoto:boolean;
  listaTraducciones: ArticuloTrad[];
  listaImagenes:string[];
}

export class ArticuloTrad {
  id: number;
  nombre: string;
  descripcion: string;
  idCategoria: number;
  idIdioma: number;
  nombreIdioma: string;
  filled: boolean;
}

export class Imagen {
  id: number;
  imagen:string;
  idArticulo:number;
}


