import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farmacias-crear',
  templateUrl: './farmacias-crear.component.html',
  styleUrls: ['./farmacias-crear.component.less']
})
export class FarmaciasCrearComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
