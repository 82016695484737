import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  ConfiguracionService,
  DepartamentosService,
} from '@app/_services';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import {
  FileInfo,
  FileRestrictions,
  SelectEvent,
  RemoveEvent,
} from '@progress/kendo-angular-upload';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IdiomasService } from '@app/_services/idiomas.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Idioma } from '../../_models/idioma';
import { CamposIdioma } from '../../_models/camposidiomas';
import { MenuService } from '@app/_services';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';


@Component({
  selector: 'app-organizaciones-detalle',
  templateUrl: './organizaciones-detalle.component.html',
})
export class OrganizacionesDetalleComponent implements OnInit {
  form: FormGroup;
  loading = false;
  isAddMode: boolean;
  codigo: string;
  imagenAvatar: any;
  imagenCabecera: any;
  apItems: string[];
  public avatarPreview;
  public cabeceraPreview;
  imagenAnt: any;
  submitted = false;
  formImErr = false;
  avatarBorrado = false;
  cabeceraBorrada = false;
  departamentos:any[] = [];
  aperturaRangos = [];
  imagenConfiguracion: any;
  isNotInsA = false;
  isNotInsC = false;
  modalReference: NgbModalRef;
  idiomasGuardar: boolean = false;
  idiomaEditando: any;
  public codigos: any[] = [];
  camposSelectedLang: number = 0;
  containersCampos: number[] = [];
  camposValoresDescripcion = [];
  camposValoresFAQ = [];
  camposValoresColaboradores = [];
  camposValoresPrivacidad = [];
  jsonCamposIdomas = [];
  dataLoaded: boolean = false;

  public listaIdiomas = [];
  public selectedLangs = [];
  public listaCampos = [];
  //Permisos
  notificacionesDisabled = false;
  faqDisabled = false;
  gesDocDisabled = false;
  contactosDisabled = false;
  facebookDisabled = false;
  twitterDisabled = false;
  flickrDisabled = false;
  instagramDisabled = false;
  youtubeDisabled = false;
  agendaDisabled = false;
  mensajesDisabled = false;
  foroDisabled = false;
  encuetasDisabled = false;
  reservasDisabled = false;
  agentesDisabled = false;
  colaboradoresDisabled = false;

  publicarDisabled = false;
  comentarDisabled = false;

  private translate: TranslateService;

  //PopUp
  public show = false;
  agentesSinAsignarLista=[];

  listaTMP=[];
  updateSubsNeeded=false;

  public showAdmins = false;
  usuariosAdminsLista=[];

  listaTMPAdmins=[];
  updateAdminsNeeded=false;

  regexLink =
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576,
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private menuService: MenuService,
    translate: TranslateService,
    private organizacionService: OrganizacionesService,
    private configuracionService: ConfiguracionService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private idiomasService: IdiomasService,
    private departamentoService: DepartamentosService,
    private departamentosEnteService: DepartamentosEnteService,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    this.translate = translate;
  }

  ngOnInit(): void {
    this.codigo = this.route.snapshot.params['id'];
    this.isAddMode = !this.codigo;
    this.dataLoaded = this.isAddMode;
    //Load Apertura Reservas Options
    this.aperturaRangos = [
      this.translateService.instant('organizaciones.opsiempre'),
      this.translateService.instant('organizaciones.opdiario'),
      this.translateService.instant('organizaciones.opsemanal'),
      this.translateService.instant('organizaciones.opmensual'),
    ];
    //Load Permisos given By Admin
    this.configuracionService
      .GetConfig()
      .pipe()
      .subscribe((result) => {
        console.log('PERMISOS LOADED =');
        console.log(result.data[0]);
        this.notificacionesDisabled = !result.data[0].activoNotificaciones;
        this.faqDisabled = !result.data[0].activoFAQ;
        this.gesDocDisabled = !result.data[0].activoGesDoc;
        this.contactosDisabled = !result.data[0].activoContactos;
        this.facebookDisabled = !result.data[0].activoFacebook;
        this.twitterDisabled = !result.data[0].activoTwitter;
        this.flickrDisabled = !result.data[0].activoFlicker;
        this.instagramDisabled = !result.data[0].activoInstagram;
        this.youtubeDisabled = !result.data[0].activoYoutube;
        this.agendaDisabled = !result.data[0].activoAgenda;
        this.mensajesDisabled = !result.data[0].activoMensajesOrg;
        this.foroDisabled = !result.data[0].activoForo;
        this.encuetasDisabled = !result.data[0].activoEncuestas;
        this.reservasDisabled = !result.data[0].activoReservas;
        this.agentesDisabled = !result.data[0].activoAgentes;
        this.colaboradoresDisabled = !result.data[0].activoColaboradores;
      });

    //Load all available Langs
    this.idiomasService
      .GetAll()
      .pipe()
      .subscribe((result) => {
        //console.log('ListaIdomas');
        //console.log(result.data)
        for (var i in result.data) {
          // Añadir a idiomas propiedad selected
          result.data[i].selected = false;
          this.listaIdiomas.push(result.data[i]);
        }
        this.dataLoaded = true;

        //Load Organization Selected Langs
        //Must load AFTER getIdiomas
        if (!this.isAddMode) {
          this.idiomasService
            .GetById(this.route.snapshot.params['id'])
            .pipe()
            .subscribe((result) => {
              //console.log('Loading Organization selected Langs ');
              for (var i in result.data) {
                //console.log(result.data[i]);
                this.selectedLangs.push(result.data[i]);
                this.containersCampos.push(result.data[i].codigo);
                for (var j in this.listaIdiomas) {
                  if (this.listaIdiomas[j].codigo == result.data[i].codigo) {
                    this.listaIdiomas[j].selected = true;
                  }
                }
              }
            });
        }
      });

    if (!this.isAddMode) {
      //EDIT ORGANIZATION

      //Load Campos of selected Idiomas
      this.idiomasService
        .getCamposByIdOrg(this.route.snapshot.params['id'])
        .pipe()
        .subscribe((result) => {
          //console.log('Loading Organization Campos of Langs ');
          for (var i in result.data) {
            //console.log(result.data[i]);
            this.camposValoresDescripcion[result.data[i].idIdioma] =
              result.data[i].descripcion;
            this.camposValoresColaboradores[result.data[i].idIdioma] =
              result.data[i].colaboradores;
            this.camposValoresPrivacidad[result.data[i].idIdioma] =
              result.data[i].privacidad;
            this.camposValoresFAQ[result.data[i].idIdioma] = result.data[i].faq;
          }
        });

      //Load Deppartments
      //TODO
      this.departamentosEnteService
        .GetById(this.codigo.toString())
        .pipe()
        .subscribe((result) => {
          for (var i in result.data) {
            this.departamentos[i]=result.data[i].nombre;
          }
          console.log("Departamentos_:");
          console.log(result);
        });

      //Load Organization
      this.organizacionService
        .GetById(this.codigo)
        .pipe()
        .subscribe((result) => {
          this.menuService.titulo = result.data[0].nombre;
          this.form = this.formBuilder.group({
            codigo: this.route.snapshot.params['id'],
            //Datos Forms Load
            nombreOrg: new FormControl(
              result.data[0].nombre,
              Validators.required
            ),
            logo: new FormControl(result.data[0].imagenAvatar),
            cabecera: new FormControl(result.data[0].imagenCabecera),
            activoOrganizacion: new FormControl(
              result.data[0].organizacionActiva
            ), //result.data[0].organizacionActiva
            //Correo Forms Load
            email: new FormControl(result.data[0].email, [Validators.email]),
            smtp: new FormControl(result.data[0].smtp),
            puerto: new FormControl(result.data[0].puerto),
            usuario: new FormControl(result.data[0].emailUsuario),
            contrasena: new FormControl(result.data[0].emailPassword),
            activoGuardarPassword: new FormControl(
              result.data[0].activoCambiarPassword
            ),
            //Informacion Forms Load
            activoFarmacias: new FormControl(
              result.data[0].infoFarmaciasActivas
            ),
            activoNotificaciones: new FormControl(
              result.data[0].infoNotificacionesActivas
            ),
            activoFAQ: new FormControl(result.data[0].infoFaqActivo),
            activoGesDoc: new FormControl(
              result.data[0].infoGestionDocumentalActiva
            ),
            activoContactos: new FormControl(
              result.data[0].infoContactosActivos
            ),
            //Agenda Forms Load
            activoAgenda: new FormControl(result.data[0].agendaActiva),
            agendaEdadMin: new FormControl(result.data[0].agendaEdadMinima),
            //Mensajeria Forms Load
            activoMensajeria: new FormControl(result.data[0].mensajeriaActiva),
            activoIncidenciasDepUnico: new FormControl(
              result.data[0].mensajeriaIncidenciasDepUnico
            ),
            departamento: new FormControl(result.data[0].idDepartamento),
            //Reservas Forms Load
            activoReservas: new FormControl(result.data[0].reservasActivas),
            activoConfirmReserva: new FormControl(
              result.data[0].reservasConfirmacion
            ),
            activoMostrarUsuarioCalendario: new FormControl(
              result.data[0].reservasUsuarioEnCalendario
            ),
            mindiasresrva: new FormControl(result.data[0].reservasDiasMinimos),
            apertura: new FormControl(result.data[0].reservasApertura),
            correosReservas: new FormControl(result.data[0].reservasCorreos),
            //Coordenadas Forms Load
            latitud: new FormControl(result.data[0].coordLatitud),
            longitud: new FormControl(result.data[0].coordLongitud),
            zoom: new FormControl(result.data[0].coordZoom),
            //Agentes Forms Load
            activoAgentes: new FormControl(result.data[0].agentesActivos),
            activoTipos: new FormControl(result.data[0].agentesTiposActivos),
            //PermUsuarios Forms Load
            permUsuariosPublicar: new FormControl(
              result.data[0].permUsuariosPublicar
            ),
            permUsuariosComentar: new FormControl(
              result.data[0].permUsuariosComentar
            ),
            //Foro Forms Load
            activoForo: new FormControl(result.data[0].foroActivo),
            edadforo: new FormControl(result.data[0].foroEdadMinima),
            foroPermUsuariosCrearPost: new FormControl(result.data[0].foroPermUsuariosCrearPost),
            //Encuestas Forms Load
            activoEncuestas: new FormControl(result.data[0].encuestasActivas),
            edadencuestasmin: new FormControl(
              result.data[0].encuestasEdadMinima
            ),
            //RRSS Forms Load
            activoFacebook: new FormControl(result.data[0].rrssFacebookActivo),
            fblink: new FormControl(result.data[0].rrssFacebookUrl),
            activoTwitter: new FormControl(result.data[0].rrssTwitterActivo),
            twlink: new FormControl(result.data[0].rrssTwitterUrl),
            activoFlicker: new FormControl(result.data[0].rrssFlickerActivo),
            fllink: new FormControl(result.data[0].rrssFlickerUrl),
            activoInstagram: new FormControl(
              result.data[0].rrssInstagramActivo
            ),
            iglink: new FormControl(result.data[0].rrssInstagramUrl),
            activoYoutube: new FormControl(result.data[0].rrssYoutubeActivo),
            ytlink: new FormControl(result.data[0].rrssYoutubeUrl),
            media: new FormControl(result.data[0].rrssMultimedia),
          });
          //Load Iamges
          if (result.data[0].imagenAvatar) {
            this.imagenAvatar = result.data[0].imagenAvatar;
            this.imagenAnt = result.data[0].imagenAvatar;
            this.avatarPreview = result.data[0].imagenAvatar;
            //this.createImageFromBase64(this.imagenAvatar, 'avatar');
          } else {
            this.imagenAnt = [];
            this.imagenAvatar = '';
          }
          if (result.data[0].imagenCabecera) {
            this.imagenCabecera = result.data[0].imagenCabecera;
            this.imagenAnt = result.data[0].imagenCabecera;
            this.cabeceraPreview = result.data[0].imagenCabecera;
            //this.createImageFromBase64(this.imagenCabecera, 'cabecera');
          } else {
            this.imagenAnt = [];
            this.imagenCabecera = '';
          }

          //Load Perms Publicar Comentar
          this.publicarDisabled = !result.data[0].canPublish;
          this.comentarDisabled = !result.data[0].canComment;
        });
    } else {
      //ADD NEW ORGANIZATION
      this.form = this.formBuilder.group({
        codigo: this.route.snapshot.params['id'],
        //Datos Forms Load
        nombreOrg: new FormControl('', Validators.required),
        logo: new FormControl(''),
        cabecera: new FormControl(''),
        activoOrganizacion: new FormControl(1),
        //Correo Forms Load
        email: new FormControl('', [Validators.email]),
        smtp: new FormControl(''),
        puerto: new FormControl(),
        usuario: new FormControl(''),
        contrasena: new FormControl(''),
        activoGuardarPassword: new FormControl(1),
        //Informacion Forms Load
        activoFarmacias: new FormControl(1),
        activoNotificaciones: new FormControl(1),
        activoFAQ: new FormControl(0),
        activoGesDoc: new FormControl(1),
        activoContactos: new FormControl(0),
        //Agenda Forms Load
        activoAgenda: new FormControl(1),
        agendaEdadMin: new FormControl(),
        //Mensajeria Forms Load
        activoMensajeria: new FormControl(1),
        activoIncidenciasDepUnico: new FormControl(1),
        departamento: new FormControl(1),
        //Reservas Forms Load
        activoReservas: new FormControl(1),
        activoConfirmReserva: new FormControl(1),
        activoMostrarUsuarioCalendario: new FormControl(0),
        mindiasresrva: new FormControl(),
        apertura: new FormControl('Diario'),
        correosReservas: new FormControl(''),
        //Coordenadas Forms Load
        latitud: new FormControl(''),
        longitud: new FormControl(''),
        zoom: new FormControl(),
        //Agentes Forms Load
        activoAgentes: new FormControl(1),
        activoTipos: new FormControl(1),
        //PermUsuario Forms Load
        permUsuariosPublicar: new FormControl(1),
        permUsuariosComentar: new FormControl(1),
        //Foro Forms Load
        activoForo: new FormControl(1),
        edadforo: new FormControl(),
        foroPermUsuariosCrearPost:new FormControl(1),
        //Encuestas Forms Load
        activoEncuestas: new FormControl(1),
        edadencuestasmin: new FormControl(),
        //RRSS Forms Load
        activoFacebook: new FormControl(1),
        fblink: new FormControl(''),
        activoTwitter: new FormControl(0),
        twlink: new FormControl(''),
        activoFlicker: new FormControl(0),
        fllink: new FormControl(''),
        activoInstagram: new FormControl(1),
        iglink: new FormControl(''),
        activoYoutube: new FormControl(0),
        ytlink: new FormControl(''),
        media: new FormControl(''),
        //Idiomas Forms Load

        //Campos Idiomas Forms Load
      });
    }
  }

  onSubmit(f) {
    this.submitted = true;
    console.log(f);
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid || this.formImErr) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearOrganizacion();
    } else {
      this.updateOrganizacion();
    }
  }

  //CREATE Y UPDATE
  private crearOrganizacion() {
    //Generate base64 image strings
    if (!this.isNotInsA) {
      this.createImageFromBase64(this.imagenAvatar, 'avatar');
      this.form.value.logo = this.imagenAvatar;
    }
    if (!this.isNotInsC) {
      this.createImageFromBase64(this.imagenCabecera, 'cabecera');
      this.form.value.cabecera = this.imagenCabecera;
    }
    //Create Organization
    this.organizacionService.insert(this.form.value).subscribe((result) => {
      if (!result.error) {
        //Send language selection creation(We need the Org id)
        console.log('RESPUESTA CREAR');
        console.log(result);

        for (let i = 0; i < this.selectedLangs.length; i++) {
          this.codigos.push(
            new Idioma(
              0,
              this.selectedLangs[i].codigo,
              this.route.snapshot.params['id']
            )
          );
        }
        //Create Selected Langs
        this.idiomasService
          .create(this.codigos, result.idorg)
          .pipe()
          .subscribe((resultIdioma) => {
            console.log('Result selected langs added:');
            console.log(resultIdioma);
          });
        //Create Campos of Selected Langs
        console.log('CAMPOS CREATION MODEL LIST');
        for (let elem in this.selectedLangs) {
          console.log(this.selectedLangs[elem].codigo);
          this.jsonCamposIdomas.push(
            new CamposIdioma(
              result.idorg,
              this.selectedLangs[elem].codigo,
              this.camposValoresDescripcion[this.selectedLangs[elem].codigo],
              this.camposValoresColaboradores[this.selectedLangs[elem].codigo],
              this.camposValoresFAQ[this.selectedLangs[elem].codigo],
              this.camposValoresPrivacidad[this.selectedLangs[elem].codigo]
            )
          );
        }
        console.log('JSON Campos');
        console.log(this.jsonCamposIdomas);

        this.idiomasService
          .insertCamposIdiomasOrg(this.jsonCamposIdomas, result.idorg)
          .pipe()
          .subscribe((resultCampos) => {
            console.log('Result Campos langs added:');
            console.log(resultCampos);
          });

        this.alertService.success(
          this.translateService.instant('botones.creadocorrectamente'),
          { keepAfterRouteChange: true }
        );
        this.router.navigate(['organizaciones']);
      } else {
        this.alertService.error(
          this.translateService.instant('enlaces.errorcrear')
        );
        this.loading = false;
      }
    });
  }

  private updateOrganizacion() {
    var error = null;
    //this.form.value.logo = this.imagenAvatar;
    //this.form.value.cabecera = this.imagenCabecera;

    // if (this.avatarBorrado) {
    //   // Caso de foto borrada
    //   this.organizacionService
    //     .deleteImagen(this.form.value)
    //     .subscribe((result) => {
    //       if (result.error) error = result.error;
    //     });
    // } else {
    //   if (this.imagenAvatar != this.imagenAnt && this.imagenAvatar != []) {
    //     // Caso de foto actualizada
    //     this.organizacionService
    //       .updateImagen(this.form.value)
    //       .subscribe((result) => {
    //         if (result.error) error = result.descripcion;
    //       });
    //   }
    // }
    //Generate base64 image strings
    if (!this.isNotInsA) {
      this.createImageFromBase64(this.imagenAvatar, 'avatar');
      this.form.value.logo = this.imagenAvatar;
    }
    if (!this.isNotInsC) {
      this.createImageFromBase64(this.imagenCabecera, 'cabecera');
      this.form.value.cabecera = this.imagenCabecera;
    }

    //Caso actualizar idiomas seleccionados para organizacion
    if (this.idiomasGuardar) {
      for (let i = 0; i < this.selectedLangs.length; i++) {
        this.codigos.push(
          new Idioma(
            0,
            this.selectedLangs[i].codigo,
            this.route.snapshot.params['id']
          )
        );
      }

      this.idiomasService
        .update(this.codigos, this.route.snapshot.params['id'])
        .pipe(first())
        .subscribe((result) => {
          //console.log('UpdatIdomasResult Error = '+result.error);
        });
    }
    //Caso actualizar Campos de diomas
    console.log('CAMPOS UPDATE MODEL LIST');
    for (let elem in this.selectedLangs) {
      console.log(this.selectedLangs[elem].codigo);
      this.jsonCamposIdomas.push(
        new CamposIdioma(
          this.route.snapshot.params['id'],
          this.selectedLangs[elem].codigo,
          this.camposValoresDescripcion[this.selectedLangs[elem].codigo],
          this.camposValoresColaboradores[this.selectedLangs[elem].codigo],
          this.camposValoresFAQ[this.selectedLangs[elem].codigo],
          this.camposValoresPrivacidad[this.selectedLangs[elem].codigo]
        )
      );
    }
    this.idiomasService
      .updateCampos(this.jsonCamposIdomas, this.route.snapshot.params['id'])
      .pipe(first())
      .subscribe((result) => {
        console.log('CamposActualizar Error = ' + result.error);
      });
    // Caso actualización de info Organizacion
    this.organizacionService
      .update(this.form.value)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error && !error) {
          this.alertService.success(
            this.translateService.instant('botones.editadocorrectamente'),
            { keepAfterRouteChange: true }
          );
          this.router.navigate(['../../'], { relativeTo: this.route });
        } else {
          this.alertService.error(
            this.translateService.instant('enlaces.erroreditar')
          );
          this.loading = false;
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  get fv() {
    return this.form.value;
  }

  public selectedLangsChange(isTrue, lang) {
    console.log('SelectedLangs');
    console.log(this.selectedLangs);
    if (isTrue) {
      this.selectedLangs.push(lang);
      //Añadimos un div IdiomasCampos más con los campos
      this.containersCampos.push(lang.codigo);
    } else {
      const index = this.selectedLangs.indexOf(lang);
      this.selectedLangs.splice(index, 1);

      const index2 = this.containersCampos.indexOf(lang.codigo);
      this.containersCampos.splice(index2, 1);
      //Delete that Lang JsonForm
      //TODO this.jsonCamposIdomas.push()
    }
    this.selectedLangs.sort((a, b) => a - b);
    this.idiomasGuardar = true;
  }

  //Procesado de Imagenes
  createImageFromBase64(image, img) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    console.log(objectURL);
    if (img == 'avatar')
      this.avatarPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    else
      this.cabeceraPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  onClickEliminarImagen(content) {
    this.modalReference = this.modalService.open(content, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.avatarPreview = image.src.toString();

          that.imagenAvatar = image.src.toString().split(',', 2)[1];

          that.avatarBorrado = false;

          that.isNotInsA = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }
  public selectEventHandlerCabecera(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.cabeceraPreview = image.src.toString();

          that.imagenCabecera = image.src.toString().split(',', 2)[1];

          that.cabeceraBorrada = false;

          that.isNotInsC = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenAvatar = '';
    this.avatarPreview = null;
  }
  public removeEventHandlerCabecera(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenCabecera = '';
    this.cabeceraPreview = null;
  }
  eliminarImagen(opt) {
    if (opt == 'avatar') {
      this.avatarBorrado = true;
      this.imagenAvatar = null;
      this.avatarPreview = null;
    } else {
      this.cabeceraBorrada = true;
      this.imagenCabecera = null;
      this.cabeceraPreview = null;
    }
    this.modalReference.close();
  }
  changDropdown(value: any) {
    switch (value) {
      case this.apItems[0]:
        this.fv.apertura = 0;
        break;
      case this.apItems[1]:
        this.fv.apertura = 1;
        break;
      default:
        break;
    }
  }
  valueIdiomaCamposChange(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log(value.codigo);
    console.log(this.camposValoresDescripcion);
    console.log(this.camposValoresColaboradores);
    console.log(this.camposValoresPrivacidad);
    console.log(this.camposValoresFAQ);
    this.camposSelectedLang = value.codigo;
  }

  //Gestion PopUps
  public loadAdminsFromEnte(){
    this.organizacionService
    .getAdminsFromEnte(this.codigo)
    .pipe(first())
    .subscribe((result: any) => {
      console.log('LLEGAN Admins');
      console.log(result);
      this.usuariosAdminsLista = result.data
      this.usuariosAdminsLista.forEach(val => this.listaTMPAdmins.push(Object.assign({}, val)));
      //console.log( this.agentesSinAsignarLista.length);
    });
  }

  btnActualizarAdmins(){
    console.log('Creando listaAdmins de cambios...');
    this.usuariosAdminsLista.forEach((value, index) => {
      console.log('LISTA = '+this.usuariosAdminsLista[index].isMine);
      console.log('LISTATMP= '+this.listaTMPAdmins[index].isMine);
      if(this.usuariosAdminsLista[index].isMine!=this.listaTMPAdmins[index].isMine){
      console.log('ACTUALIZA!!');
        this.updateAdminsNeeded= true;
        this.usuariosAdminsLista[index].actualizar=true;
      }
    });


    if(this.updateAdminsNeeded){
      console.log('Enviando lista Admins');
      console.log(this.usuariosAdminsLista);
    this.organizacionService
      .updateAdmins(this.usuariosAdminsLista,this.codigo)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error ) {
          this.showAdmins=false;
          this.alertService.success(
            this.translateService.instant('botones.editadocorrectamente'),
            { keepAfterRouteChange: true }
          );
        } else {
          this.alertService.error(
            this.translateService.instant('enlaces.erroreditar')
          );
          this.loading = false;
          this.showAdmins=false;
        }
      });
    }
    else{
      this.showAdmins=false;
    }

  }


  public loadAvailableAgents(){
    this.organizacionService
    .getAllWithoutFather(this.codigo)
    .pipe(first())
    .subscribe((result: any) => {
      console.log('LLEGAN AGENTES HUERFANOS O MIOS');
      console.log(result);
      this.agentesSinAsignarLista = result.data
      this.agentesSinAsignarLista.forEach(val => this.listaTMP.push(Object.assign({}, val)));
      console.log( this.agentesSinAsignarLista.length);

    });
  }

  btnActualizarSubAgentes(){

    console.log('Creando lista');
    this.agentesSinAsignarLista.forEach((value, index) => {
      console.log('LISTA = '+this.agentesSinAsignarLista[index].isMine);
      console.log('LISTATMP= '+this.listaTMP[index].isMine);
      if(this.agentesSinAsignarLista[index].isMine!=this.listaTMP[index].isMine){
      console.log('ACTUALIZAQ!!');
        this.updateSubsNeeded= true;
        this.agentesSinAsignarLista[index].actualizar=true;
      }

  });
    // this.agentesSinAsignarLista.forEach(element => {
    //   this.listaTMP.forEach(elementTMP => {
    //     console.log('[Elelment= '+element.isMine+' / '+elementTMP.isMine+']');
    //     if(element.isMine!=elementTMP.isMine){
    //       this.updateSubsNeeded= true;
    //       element.actualizar=true;
    //     }
    //   });
    // });
    if(this.updateSubsNeeded){
      console.log('Enviando lista');
      console.log(this.agentesSinAsignarLista);
    this.organizacionService
      .updateSubAgentes2(this.agentesSinAsignarLista,this.codigo)
      .pipe(first())
      .subscribe((result) => {
        if (!result.error ) {
          
          this.show=false;
          this.alertService.success(
            this.translateService.instant('botones.editadocorrectamente'),
            { keepAfterRouteChange: true }
          );
        } else {
          this.alertService.error(
            this.translateService.instant('enlaces.erroreditar')
          );
          this.loading = false;
          this.show=false;
        }
      });
    }
    else{
      this.show=false;
    }

  }


  public openGestionSubs(): void {
    this.show = !this.show;
    if(this.agentesSinAsignarLista.length==0)
   this.loadAvailableAgents();
  }
  public openGestionAdmins(): void {
    this.showAdmins = !this.showAdmins;
    if(this.usuariosAdminsLista.length==0)
    this.loadAdminsFromEnte();
  }



  
  onClickGestionDeps() {
    this.router.navigate(['departamentos-ente',{ idorg:this.codigo }]);
  }
  //,{ idorg:this.route.snapshot.params['id'] }
  onClickGestionContactos(){
    this.router.navigate(['contactos-ente',{ idorg:this.codigo }]);
  }

  cerrarPopUp(){
    this.show=false;
  }
  cerrarPopUpAdmins(){
    this.showAdmins=false;
  }
}
