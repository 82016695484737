import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad, Sala } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-recintos-editar',
  templateUrl: './recintos-editar.component.html',
})
export class RecintosEditarComponent implements OnInit {
  listaIdiomas: Idioma[] = [];
  listaSalas: Sala[]=[];
  myRecinto: Recinto = new Recinto();
  nombreRecinto: string = '';
  descripcionRecinto: string = '';
  superficieRecinto: string = "";
  imagenB64: string = '';
  selectedIdioma: Idioma;
  recintoActivo: boolean = true;
  filePath: string;

  lang;
  private translate: TranslateService;
  idOrg: string;
  idRecinto: number;
  constructor(
    private recintosService: RecintosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera');
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRecinto = this.route.snapshot.params['idrecinto'];
    this.idiomaService
      .getIdiomasFromEnte(this.idOrg)
      .subscribe((resultidiomas) => {
        this.listaIdiomas = resultidiomas.data;
        if (this.listaIdiomas.length > 0) {
          this.selectedIdioma = this.listaIdiomas[0];
          //GET RECINTO
          this.recintosService
            .GetRecintoByIdLang(this.idRecinto, this.selectedIdioma.codigo)
            .subscribe((resultRec) => {
              console.log(resultRec);
              this.myRecinto = resultRec.data[0];
              this.nombreRecinto = resultRec.data[0].nombre;
              this.descripcionRecinto = resultRec.data[0].descripcion;
              this.superficieRecinto = resultRec.data[0].superficie;
              this.recintoActivo = resultRec.data[0].activo;
              this.filePath=resultRec.data[0].imagen;
            });
        }
      });
  }

  loadRecinto(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Recinto Idioma :' + value.codigo);
    this.recintosService
      .GetRecintoByIdLang(this.idRecinto, this.selectedIdioma.codigo)
      .subscribe((result) => {
        this.nombreRecinto = result.data[0].nombre;
        this.descripcionRecinto = result.data[0].descripcion;
        this.superficieRecinto = result.data[0].superficie;
        this.recintoActivo = result.data[0].activo;
      });
  }

  btnActualizarRecinto() {
    if (this.nombreRecinto==null || this.nombreRecinto.length < 1 || this.descripcionRecinto==null || this.descripcionRecinto.length < 1) {
      this.alertService.info(
        this.translateService.instant('recintos.validacion')
      );
      return;
    }


    if(this.filePath!=this.myRecinto.imagen){
      console.log('Obteniendo en BASE64...');
      this.myRecinto.imagen = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myRecinto.updateFoto=true;
    }
    this.myRecinto.activo = this.recintoActivo;
    this.myRecinto.nombre = this.nombreRecinto;
    console.log(this.superficieRecinto);
    this.myRecinto.superficie = this.superficieRecinto;
    this.myRecinto.descripcion = this.descripcionRecinto;
    this.myRecinto.idIdioma = this.selectedIdioma.codigo;

    this.recintosService
      .actualizarRecinto(this.myRecinto)
      .subscribe((resultUpdate) => {
        console.log(resultUpdate);
        if (!resultUpdate.error) {
          this.alertService.success(
            this.translateService.instant('recintos.successupdate')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorupdate')
          );
        }
      });
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['recintos']);
  }
}
