<h3>Mensajes</h3>
<kendo-dropdownlist
  #dropdown
  [data]="listaEntesAdministrados"
  textField="nombre"
  valueField="id"
  [(ngModel)]="selectedEnte"
  (valueChange)="loadPeticiones($event, dropdown)"
>
</kendo-dropdownlist>

<kendo-grid
  [kendoGridBinding]="listaMensajes"
  [sortable]="true"
  kendoGridSelectBy="id"
  [navigable]="true"
  [filterable]="true"
  (cellClick)="cellClick()"
  [selectedKeys]="seleccionados"
  [pageSize]="20"
  [pageable]="true"
>
  <ng-template kendoGridToolbarTemplate position="top">
    <button
      kendoButton
      (click)="onClickEliminar(content)"
      class="btn mr-1 btn-danger btn-sm mr-1"
      [disabled]="isDeleting"
    >
      {{ "botones.eliminar" | translate }}
    </button>
  </ng-template>
  <kendo-grid-checkbox-column
    width="2%"
    showSelectAll="true"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="nombreUsuario"
    title="{{ 'mensajeria.nombreusuario' | translate }}"
    [headerStyle]="{
      'background-color': '#666',
      color: '#fff',
      'line-height': '1em'
    }"
  >
  </kendo-grid-column>
  <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="email"
    title="{{ 'mensajeria.email' | translate }}"
    [headerStyle]="{
      'background-color': '#666',
      color: '#fff',
      'line-height': '1em'
    }"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="fechaNacimiento"
    title="{{ 'mensajeria.grid_lbl_fecha_nacimiento' | translate }}"
    width="15%"
    [headerStyle]="{
      'background-color': '#666',
      color: '#fff',
      'line-height': '1em'
    }"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.fecha | date: "dd/MM/yyyy" }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="aceptada"
    title="{{ 'mensajeria.grid_lbl_aceptada' | translate }}"
    [headerStyle]="{
      'background-color': '#666',
      color: '#fff',
      'line-height': '1em'
    }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.activo" disabled />
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
