import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ConfiguracionService, MenuService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileInfo, FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filePresentation, password } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html'
})
export class ConfiguracionComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  imagenConfiguracion: any;
  cambioCon: boolean = false;
  apItems: string[];
  public events: string[] = [];
  public imagePreview;
  loading = false;
  submitted = false;
  modalReference: NgbModalRef;
  imagenAnt: any;
  isNotIns = false;
  imagenBorrada = false;
  formImErr = false;

  regexLink = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
  regexEmails = '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4}(,|, )?)+$';
  regexCoor = '(-)?([0-9]+([.][0-9]*)?|[.][0-9]+)';
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576
  };


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private configuracionService: ConfiguracionService,
    private alertService: AlertService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('configuracion.configuracion');

    this.apItems = [this.translateService.instant('configuracion.apsiempre'), this.translateService.instant('configuracion.apnunca')]

    this.configuracionService.init()
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.info(this.translateService.instant('configuracion.init'));
        }

        this.configuracionService.GetConf()
          .pipe()
          .subscribe((result) => { // result.data[0]....
            this.form = this.formBuilder.group({
              // Datos
              nombre: new FormControl(result.data[0].nombre),
              texto1: new FormControl(result.data[0].texto1),
              texto2: new FormControl(result.data[0].texto2),
              logo: new FormControl(result.data[0].logo),
              // Correo
              email: new FormControl(result.data[0].email, [Validators.email]),
              smtp: new FormControl(result.data[0].smtp),
              puerto: new FormControl(result.data[0].puerto),
              usuario: new FormControl(result.data[0].usuario),
              contrasena: new FormControl(result.data[0].contrasena, [Validators.required, Validators.minLength(6)]),
              // Coordenadas (falta)
              latitud: new FormControl(result.datacoor[0].latitud, [Validators.pattern(this.regexCoor), Validators.min(-90), Validators.max(90)]),
              longitud: new FormControl(result.datacoor[0].longitud, [Validators.pattern(this.regexCoor), Validators.min(-180), Validators.max(180)]),
              zoomcor: new FormControl(result.datacoor[0].zoom),
              // Comunicación
              noticias: new FormControl(result.data[0].noticias),
              avisos: new FormControl(result.data[0].avisos),
              tablon: new FormControl(result.data[0].tablon),
              mensajeria: new FormControl(result.data[0].mensajeria),
              agenda: new FormControl(result.data[0].agenda),
              // Información
              farmacias: new FormControl(result.data[0].farmacias),
              transportes: new FormControl(result.data[0].transportes),
              contactos: new FormControl(result.data[0].contactos),
              sitios: new FormControl(result.data[0].sitios),
              documentos: new FormControl(result.data[0].documentos), // Revisar
              // Incidencias
              incidencias: new FormControl(result.data[0].incidencias),
              departUnicoIncid: new FormControl(result.data[0].departUnicoIncid),
              // Reservas
              reservas: new FormControl(result.data[0].reservas),
              confirmarreserva: new FormControl(result.data[0].confirmarreserva),
              mostrarusuarioreserva: new FormControl(result.data[0].mostrarusuarioreserva),
              mindiaslaborables: new FormControl(result.data[0].mindiaslaborables),
              apertura: new FormControl(result.data[0].apertura), // revisar dropdown
              correosReservas: new FormControl(result.datacorr[1].emails, [Validators.pattern(this.regexEmails)]),
              // Agentes
              agentes: new FormControl(result.data[0].agentes),
              agentesUnificados: new FormControl(result.data[0].agentesUnificados),
              grupos: new FormControl(result.data[0].grupos),
              // Texto login
              menuTurista: new FormControl(result.data[0].menuTurista),
              agendaPublica: new FormControl(result.data[0].agendaPublica),
              textoTuristaES: new FormControl(result.data[0].textoTuristaES),
              textoTuristaEU: new FormControl(result.data[0].textoTuristaEU),
              // Foro
              foro: new FormControl(result.data[0].foro),
              edadforo: new FormControl(result.data[0].edadforo),
              // Encuestas
              encuestas: new FormControl(result.data[0].encuestas),
              edadencuestasmin: new FormControl(result.data[0].edadencuestasmin),
              // Redes sociales
              facebook: new FormControl(result.data[0].facebook),
              twitter: new FormControl(result.data[0].twitter),
              flicker: new FormControl(result.data[0].flicker),
              instagram: new FormControl(result.data[0].instagram),
              youtube: new FormControl(result.data[0].youtube),
              // Enlaces de redes
              fblink: new FormControl(result.datarrss[0].link, [Validators.pattern(this.regexLink)]),
              twlink: new FormControl(result.datarrss[1].link, [Validators.pattern(this.regexLink)]),
              fllink: new FormControl(result.datarrss[2].link, [Validators.pattern(this.regexLink)]),
              inslink: new FormControl(result.datarrss[3].link, [Validators.pattern(this.regexLink)]),
              ytlink: new FormControl(result.datarrss[4].link, [Validators.pattern(this.regexLink)]),
              media: new FormControl(result.datacorr[2].emails, [Validators.pattern(this.regexEmails)]),
              // Censo
              censo: new FormControl(result.data[0].censo),
              correosCenso: new FormControl(result.datacorr[0].emails, [Validators.pattern(this.regexEmails)]),
              // Privacidad
              textEULA1: new FormControl(result.data[0].textEULA1),
              textEULA2: new FormControl(result.data[0].textEULA2)
            });

            if (result.data[0].logo) {
              this.imagenConfiguracion = result.data[0].logo;
              this.imagenAnt = result.data[0].logo;
              this.createImageFromBase64(this.imagenConfiguracion);
            } else {
              this.imagenAnt = [];
              this.imagenConfiguracion = [];
            }

          });
      });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('configuracion.configuracion');
    });
  }

  // convenience getter and setter for easy access to form fields
  get f() { return this.form.controls; }
  get fv() { return this.form.value; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid || this.formImErr) {
      return;
    }

    this.loading = true;
    this.updateConfiguracion();
  }

  private updateConfiguracion() {
    this.form.value.logo = this.imagenConfiguracion;

    if (this.imagenBorrada || this.imagenAnt.length != this.imagenConfiguracion.length) {
      this.configuracionService.update(this.form.value, this.cambioCon)
        .pipe(first())
        .subscribe((result) => {
          if (!result.error) {
            this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
            this.loading = false;
            this.router.navigate(['/configuracion']);
          } else {
            this.alertService.error(this.translateService.instant('configuracion.erroreditar'));
            this.loading = false;
          }
        });
    } else {
      this.configuracionService.updateSin(this.form.value, this.cambioCon)
        .pipe(first())
        .subscribe((result) => {
          if (!result.error) {
            this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
            this.loading = false;
            this.router.navigate(['/configuracion']);
          } else {
            this.alertService.error(this.translateService.instant('configuracion.erroreditar'));
            this.loading = false;
          }
        });
    }
  }

  // Parte foto
  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onClickEliminarImagen(content) {
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  eliminarImagen() {
    this.imagenBorrada = true;
    this.imagenConfiguracion = [];
    this.imagePreview = null;
    this.modalReference.close();
  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {

      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreview = image.src.toString();

          that.imagenConfiguracion = image.src.toString().split(",", 2)[1];

          that.imagenBorrada = false;

          that.isNotIns = true;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenConfiguracion = [];
    this.imagePreview = null;
  }

  changeDropdown(value: any) {

    switch (value) {
      case this.apItems[0]:
        this.fv.apertura = 0;
        break;
      case this.apItems[1]:
        this.fv.apertura = 1;
        break;
      default:
        break;
    }
  }

}
