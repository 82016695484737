import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
  ContactosService,
  TransportesService,
  EnlacesService,
  RecorridosService,
  DepartamentosService,
  ConfiguracionService,
} from '@app/_services';
import { ContactosenteService } from './_services/contactosente.service';
import { PublicacionesService } from './_services/publicaciones.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { AgentesService } from '@app/_services/agentes.service';
import { ColaboradoresService } from '@app/_services/colaboradores.service';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { EventosService } from '@app/_services/eventos.service';
import { RecintosService } from '@app/_services/recintos.service';
import { RecorridosEnteService } from '@app/_services/recorridos-ente.service';
import { EcommerceService } from "@app/_services/ecommerce.service";
import { MensajeriaService } from "@app/_services/mensajeria.service";

//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import {
  DropDownsModule,
  MultiSelectModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ContactosDetalleComponent } from './contactos/contactos-detalle/contactos-detalle.component';
import { TransportesComponent } from './transportes/transportes.component';
import { TransportesDetalleComponent } from './transportes/transportes-detalle/transportes-detalle.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { EnlacesDetalleComponent } from './enlaces/enlaces-detalle/enlaces-detalle.component';
import { RecorridosComponent } from './recorridos/recorridos.component';
import { RecorridosDetalleComponent } from './recorridos/recorridos-detalle/recorridos-detalle.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosDetalleComponent } from './departamentos/departamentos-detalle/departamentos-detalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PruebaComponent } from './prueba/prueba.component';
import { PatrocinadoresComponent } from './patrocinadores/patrocinadores.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionesDetalleComponent } from './organizaciones/organizaciones-detalle/organizaciones-detalle.component';
import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { AgentesComponent } from './agentes/agentes.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { AgentesdetalleComponent } from './agentes/agentesdetalle/agentesdetalle.component';
import { ColaboradoresdetalleComponent } from './colaboradores/colaboradoresdetalle/colaboradoresdetalle.component';
import { AgentesTiposComponent } from './agentes-tipos/agentes-tipos.component';
import { AgentesTiposDetallesComponent } from './agentes-tipos/agentes-tipos-detalles/agentes-tipos-detalles.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DepartamentosEnteComponent } from './departamentos-ente/departamentos-ente.component';
import { DepartamentosEnteDetallesComponent } from './departamentos-ente/departamentos-ente-detalles/departamentos-ente-detalles.component';
import { DepartamentosEnteCrearComponent } from './departamentos-ente/departamentos-ente-crear/departamentos-ente-crear.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ContactosEnteComponent } from './contactos-ente/contactos-ente.component';
import { ContactosEnteDetallesComponent } from './contactos-ente/contactos-ente-detalles/contactos-ente-detalles.component';
import { PatrocinadoresMultinivelComponent } from './patrocinadores-multinivel/patrocinadores-multinivel.component';
import { ContactosEnteCrearComponent } from './contactos-ente/contactos-ente-crear/contactos-ente-crear.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { EventosComponent } from './eventos/eventos.component';
import { EventosCrearComponent } from './eventos/eventos-crear/eventos-crear.component';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { EncuestasCrearComponent } from './encuestas/encuestas-crear/encuestas-crear.component';
import { PublicacionesListaComponent } from './publicaciones/publicaciones-lista/publicaciones-lista.component';
import { PublicacionesEditarComponent } from './publicaciones/publicaciones-editar/publicaciones-editar.component';
import { EventosEditarComponent } from './eventos/eventos-editar/eventos-editar.component';
import { EncuestasEditarComponent } from './encuestas/encuestas-editar/encuestas-editar.component';
import { CategoriasPublicacionesComponent } from './publicaciones/categorias/categorias-publicaciones/categorias-publicaciones.component';
import { CategoriasPublicacionesCrearComponent } from './publicaciones/categorias/categorias-publicaciones-crear/categorias-publicaciones-crear.component';
import { CategoriasPublicacionesEditarComponent } from './publicaciones/categorias/categorias-publicaciones-editar/categorias-publicaciones-editar.component';
import { CategoriasEventosComponent } from './eventos/categorias/categorias-eventos/categorias-eventos.component';
import { CategoriasEventosCrearComponent } from './eventos/categorias/categorias-eventos-crear/categorias-eventos-crear.component';
import { CategoriasEventosEditarComponent } from './eventos/categorias/categorias-eventos-editar/categorias-eventos-editar.component';
import { CategoriasEncuestasComponent } from './encuestas/categorias/categorias-encuestas/categorias-encuestas.component';
import { CategoriasEncuestasCrearComponent } from './encuestas/categorias/categorias-encuestas-crear/categorias-encuestas-crear.component';
import { CategoriasEncuestasEditarComponent } from './encuestas/categorias/categorias-encuestas-editar/categorias-encuestas-editar.component';
import { DropdownIdiomasEnteComponent } from './reusables/dropdown-idiomas-ente/dropdown-idiomas-ente.component';
import { TestReusableComponent } from './reusables/test-reusable/test-reusable.component';
import { AppCrearComponent } from '@app/patrocinadores-multinivel/app-crear/app-crear.component';
import { AppEditarComponent } from '@app/patrocinadores-multinivel/app-editar/app-editar.component';
import { RecintosComponent } from './recintos/recintos/recintos.component';
import { RecintosCrearComponent } from './recintos/recintos-crear/recintos-crear.component';
import { RecintosEditarComponent } from './recintos/recintos-editar/recintos-editar.component';
import { SalasComponent } from './salas/salas/salas.component';
import { SalasCrearComponent } from './salas/salas-crear/salas-crear.component';
import { SalasEditarComponent } from './salas/salas-editar/salas-editar.component';
import { CalendarioReservasComponent } from './reservas/calendario-reservas/calendario-reservas.component';
import { CalendarioGestionReservasComponent } from './reservas/calendario-gestion-reservas/calendario-gestion-reservas.component';
import { ReservasCrearComponent } from './reservas/reservas-crear/reservas-crear.component';
import { ReservasEditarComponent } from './reservas/reservas-editar/reservas-editar.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { SelectorUsuariosComponent } from './reusables/selector-usuarios/selector-usuarios.component';
import { FarmaciasComponent } from './farmacias/farmacias/farmacias.component';
import { FarmaciasCrearComponent } from './farmacias/farmacias-crear/farmacias-crear.component';
import { FarmaciasEditarComponent } from './farmacias/farmacias-editar/farmacias-editar.component';
import { RecorridosEnteComponent } from '@app/recorridos-ente/recorridos-ente/recorridos-ente.component';
import { RecorridosCrearComponent } from '@app/recorridos-ente/recorridos-crear/recorridos-crear.component';
import { RecorridosEditarComponent } from '@app/recorridos-ente/recorridos-editar/recorridos-editar.component';
import { ArticulosComponent } from './ecommerce/articulos/articulos.component';
import { ArticulosCrearComponent } from './ecommerce/articulos-crear/articulos-crear.component';
import { ArticulosEditarComponent } from './ecommerce/articulos-editar/articulos-editar.component';
import { CategoriasArticulosComponent } from './ecommerce/categorias-articulos/categorias-articulos.component';
import { CategoriasArticulosCrearComponent } from './ecommerce/categorias-articulos-crear/categorias-articulos-crear.component';
import { CategoriasArticulosEditarComponent } from './ecommerce/categorias-articulos-editar/categorias-articulos-editar.component';
import { PeticionesComponent } from "./membresia/peticiones/peticiones.component";
import { PeticionMensajeComponent } from './membresia/peticion-mensaje/peticion-mensaje.component';
import { SorteoComponent } from './publicaciones/sorteo/sorteo.component';


@NgModule({
  imports: [
    BrowserModule,
    IntlModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    SortableModule,
    MultiSelectModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
    NgbModule,
    UploadsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    IconsModule,
    PopupModule,
    DialogsModule,
    ListViewModule,
    SchedulerModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    ContactosComponent,
    ContactosDetalleComponent,
    TransportesComponent,
    TransportesDetalleComponent,
    DetailsComponent,
    UpdateComponent,
    EnlacesComponent,
    EnlacesDetalleComponent,
    RecorridosComponent,
    RecorridosDetalleComponent,
    DepartamentosComponent,
    DepartamentosDetalleComponent,
    ConfiguracionComponent,
    PruebaComponent,
    PatrocinadoresComponent,
    OrganizacionesComponent,
    OrganizacionesDetalleComponent,
    ConfiguracionInicialComponent,
    AgentesComponent,
    AgentesdetalleComponent,
    ColaboradoresComponent,
    ColaboradoresdetalleComponent,
    AgentesTiposComponent,
    AgentesTiposDetallesComponent,
    DepartamentosEnteComponent,
    DepartamentosEnteDetallesComponent,
    DepartamentosEnteCrearComponent,
    ContactosEnteComponent,
    ContactosEnteDetallesComponent,
    PatrocinadoresMultinivelComponent,
    ContactosEnteCrearComponent,
    PublicacionesComponent,
    EventosComponent,
    EventosCrearComponent,
    EncuestasComponent,
    EncuestasCrearComponent,
    PublicacionesListaComponent,
    PublicacionesEditarComponent,
    EventosEditarComponent,
    EncuestasEditarComponent,
    CategoriasPublicacionesComponent,
    CategoriasPublicacionesCrearComponent,
    CategoriasPublicacionesEditarComponent,
    CategoriasEventosComponent,
    CategoriasEventosCrearComponent,
    CategoriasEventosEditarComponent,
    CategoriasEncuestasComponent,
    CategoriasEncuestasCrearComponent,
    CategoriasEncuestasEditarComponent,
    DropdownIdiomasEnteComponent,
    TestReusableComponent,
    AppCrearComponent,
    AppEditarComponent,
    RecintosComponent,
    RecintosCrearComponent,
    RecintosEditarComponent,
    SalasComponent,
    SalasCrearComponent,
    SalasEditarComponent,
    CalendarioReservasComponent,
    CalendarioGestionReservasComponent,
    ReservasCrearComponent,
    ReservasEditarComponent,
    SelectorUsuariosComponent,
    FarmaciasComponent,
    FarmaciasCrearComponent,
    FarmaciasEditarComponent,
    RecorridosEnteComponent,
    RecorridosCrearComponent,
    RecorridosEditarComponent,
    ArticulosComponent,
    ArticulosCrearComponent,
    ArticulosEditarComponent,
    CategoriasArticulosComponent,
    CategoriasArticulosCrearComponent,
    CategoriasArticulosEditarComponent,
    PeticionesComponent,
    PeticionMensajeComponent,
    SorteoComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    ContactosService,
    TransportesService,
    EnlacesService,
    RecorridosService,
    DepartamentosService,
    DepartamentosEnteService,
    ConfiguracionService,
    OrganizacionesService,
    IdiomasService,
    AgentesService,
    ColaboradoresService,
    ContactosenteService,
    PublicacionesService,
    EventosService,
    RecintosService,
    RecorridosEnteService,
    EcommerceService,
    MensajeriaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
