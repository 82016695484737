import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import {
  AlertService,
  DepartamentosService,
  UsuariosService,
} from '@app/_services';
import { Recinto, RecintoTrad, Sala, SalaTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Usuario } from '@app/_models';
@Component({
  selector: 'app-salas-editar',
  templateUrl: './salas-editar.component.html',
})
export class SalasEditarComponent implements OnInit {
  //Listas

  listaIdiomas: Idioma[] = [];

  //Permisos Sala
  listaAdminsSala: Usuario[] = []; // Pueden reservar directo
  listaUsuariosSala: Usuario[] = []; // Pueden reservar pero necesitan confirmacion

  //Data
  nombreSala: string = '';
  descripcionSala: string = '';
  superficieSala: number;
  aforo: number;
  imagenB64: string = '';
  selectedIdioma: Idioma;
  salaActiva: boolean = true;
  salaPrivada: boolean = true;
  salaHasTarifa: boolean = true;
  salaMustConfirmar: boolean = true;
  correosEnviarReserva: string;
  latitud: string = '';
  longitud: string = '';
  numDiasReserva: number;
  horasReservableDia:number;
  mySala: Sala = new Sala();

  filePath: string;

  lang;
  private translate: TranslateService;
  idOrg: string;
  idSala: number;
  idRecinto: number = 0;

  constructor(
    private recintosService: RecintosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private usuariosService: UsuariosService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRecinto = this.route.snapshot.params['idrecinto'];
    this.idSala = this.route.snapshot.params['idsala'];
    //Load Langs
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0) {
        this.selectedIdioma = this.listaIdiomas[0];
        this.recintosService
          .getSalaByIdLang(this.idSala, this.selectedIdioma.codigo)
          .subscribe((resultSala) => {
            console.log(resultSala);
            this.mySala = resultSala.data[0];
            this.nombreSala = this.mySala.nombre;
            this.descripcionSala = this.mySala.descripcion;
            this.superficieSala = Number(resultSala.data[0].superficie);
            this.horasReservableDia = resultSala.data[0].horasReservablesDia;
            this.numDiasReserva = resultSala.data[0].numDiasReserva;
            this.aforo = this.mySala.aforo;
            this.salaPrivada = this.mySala.isPrivado;
            this.salaHasTarifa = this.mySala.tarifaActiva;
            this.salaMustConfirmar = this.mySala.confirmarReserva;
            this.correosEnviarReserva = this.mySala.emails;
            this.latitud = this.mySala.latitud;
            this.longitud = this.mySala.longitud;
            this.filePath = resultSala.data[0].imagen;
          });
      }
    });

    this.recintosService
      .getAllUsersWithAdminsAsignados(this.idSala)
      .subscribe((resultUsers) => {
        this.listaAdminsSala = resultUsers.data;
      });

    this.recintosService
      .getAllUsersWithUsuariosAsignados(this.idSala)
      .subscribe((resultUsers) => {
        this.listaUsuariosSala = resultUsers.data;
      });
  }

  loadSala(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Sala Idioma :' + value.codigo);
    this.recintosService
      .getSalaByIdLang(this.idSala, this.selectedIdioma.codigo)
      .subscribe((result) => {
        this.nombreSala = result.data[0].nombre;
        this.descripcionSala = result.data[0].descripcion;
      });
  }

  btnActualizarSala() {
    console.log('Actualizando Sala');

    if (this.filePath != this.mySala.imagen) {
      console.log('Obteniendo en BASE64...');
      this.mySala.imagen = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.mySala.updateFoto = true;
    }

    //Subir Foto  y EnviarNoticia
    this.mySala.idOrg = this.idOrg;
    this.mySala.idIdioma = this.selectedIdioma.codigo;
    this.mySala.idRecinto = this.idRecinto;
    this.mySala.activo = this.salaActiva;
    this.mySala.nombre = this.nombreSala;
    this.mySala.superficie = Number(this.superficieSala);
    this.mySala.descripcion = this.descripcionSala;
    this.mySala.horasReservablesDia = this.horasReservableDia;
    this.mySala.numDiasReserva = this.numDiasReserva;
    this.mySala.aforo = this.aforo;
    this.mySala.isPrivado = this.salaPrivada;
    this.mySala.tarifaActiva = this.salaHasTarifa;
    this.mySala.confirmarReserva = this.salaMustConfirmar;
    this.mySala.emails = this.correosEnviarReserva;
    this.mySala.latitud = this.latitud;
    this.mySala.longitud = this.longitud;
    this.mySala.listaAdminsSala = this.listaAdminsSala.filter(
      (user) => user.asignado
    );
    if(this.salaPrivada)
    this.mySala.listaUsuariosSala = this.listaUsuariosSala.filter(
      (user) => user.asignado
    );
    else // Si se asigna privada se borran los usuarios de la sala para cuando vuelva a ser privada no haya usuarios ya registrados
    this.mySala.listaUsuariosSala=[];

    console.log('Enviando sala');
    this.recintosService.actualizarSala(this.mySala).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('recintos.sucessupdatesala')
        );
        this.router.navigate(['salas']);
      } else {
        console.log('Error enviando traducciones publicacion');
        this.alertService.error(
          this.translateService.instant('recintos.errorupdatesala')
        );
        return;
      }
    });
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnGestionReservas(){
    this.router.navigate([
      'reservas',
      { idorg: this.idOrg, idrecinto:this.idRecinto, idsala: this.idSala },
    ]);
  }

  btnCancelar() {
    this.router.navigate(['salas']);
  }
}
