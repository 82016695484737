import { Component, OnInit } from '@angular/core';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {
  form: FormGroup;
  dataUsuarios: Usuario[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/usuarios') {
          this.menuService.titulo = this.translateService.instant('usuarios.usuarios');
          this.cargarDatos();
        }else{
          if (this.router.url == '/usuarios-tipos') {
            this.menuService.titulo = this.translateService.instant('usuarios.usuarios');
          }
        }
      }
    });
  }

  cargarDatos() {
    this.usuariosService.getAll()
      .pipe(first())
      .subscribe(users =>
        this.dataUsuarios = users
      );
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('usuarios.usuarios');
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('usuarios.usuarios');
    });
  }
  
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['usuarios/editar/', this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['usuarios/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['usuarios/crear']);
  }
  onClickEliminar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        const user = this.dataUsuarios.find(x => x.id == element);
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          id: element
      });
        this.usuariosService.delete(element, this.form.value)
          .pipe(first())
          .subscribe(() => {
            this.dataUsuarios = this.dataUsuarios.filter(x => x.id !== element)
            this.isDeleting = false;
          });
      }
    });
  }

}
