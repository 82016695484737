import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Evento,EventoModel,EventoTraduccion ,Recurrencia } from "@app/_models/evento";
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';

@Component({
  selector: 'app-eventos-editar',
  templateUrl: './eventos-editar.component.html',
})
export class EventosEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaCategorias: Categoria[] = [];
  listaRecurrencias:Recurrencia[]=[];

  //Traducciones
  listaTraduccionesEnviar:EventoTraduccion[]=[];
  //Data
  idOrg:string;
  idEvento:number;
  titulo: string = '';
  cuerpo: string = '';
  imagenB64:string="";
  selectedIdioma: Idioma;
  selectedOrg: Ente;
  selectedCategoria: Categoria= new Categoria();
  selectedRecurrencia: Recurrencia= new Recurrencia();
  isAllDay: boolean;
  isRecurrente: boolean;
  isBusy:boolean=false;

  myEvento:Evento=new Evento();
  filePath: string;
  public fechaInicio: Date = new Date();
  public fechaFin: Date = new Date();
  public horaInicio: Date = new Date();
  public horaFin: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  lang;
  private translate: TranslateService;

  constructor(
    private eventoService: EventosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('eventos.cabecera');

    this.idOrg = this.route.snapshot.params['idorg'];
    this.idEvento = this.route.snapshot.params['idevento'];

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      this.selectedIdioma=this.listaIdiomas[0];
      
      this.eventoService
        .getEventoWithLang(this.idEvento,this.selectedIdioma.codigo)
        .subscribe((result) => {
          console.log('Evento que llega:');
          console.log(result.data);
          this.myEvento=result.data[0];
          console.log(this.myEvento);
          this.titulo=this.myEvento.titulo;
          this.cuerpo=this.myEvento.descripcion;
          this.filePath=this.myEvento.fotoUrl;
          this.myEvento.fechaInciio= new Date(this.myEvento.fechaInciio);
          this.myEvento.fechaFin = new Date(this.myEvento.fechaFin);
          console.log('fechas: ');
          console.log(this.myEvento.fechaInciio);
          this.fechaInicio = this.myEvento.fechaInciio;
          this.horaInicio = new Date(this.myEvento.fechaInciio.getTime());
          this.fechaFin = this.myEvento.fechaFin;
          this.horaFin = new Date(this.myEvento.fechaFin.getTime());
          this.isAllDay=this.myEvento.isAllDay;
          this.isRecurrente=this.myEvento.recurrenciaAnual || this.myEvento.recurrenciaMensual || this.myEvento.recurrenciaSemanal || this.myEvento.recurrenciaDiario;

          this.listaRecurrencias = this.eventoService.getRecurrencias();

          if(this.isRecurrente){
            let selected:number=0;
            if(this.myEvento.recurrenciaAnual)selected=1;
            if(this.myEvento.recurrenciaMensual)selected=2;
            if(this.myEvento.recurrenciaSemanal)selected=3;
            if(this.myEvento.recurrenciaDiario)selected=4;
            this.listaRecurrencias.forEach(element => {
              if(element.id==selected){
                this.selectedRecurrencia=element;
              }
            });
          }
        });

        this.eventoService
        .loadCategorias(this.selectedIdioma.codigo.toString(), this.idOrg)
        .subscribe((result) => {
          this.listaCategorias = result.data;
          console.log('Categorías Cargadas');
          this.listaCategorias.forEach(element => {
            if(element.id==this.myEvento.idCategoria){
                this.selectedCategoria=element;
            }
          });
        });
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();


  }



  loadEvento(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Categorias de :' + value.codigo);
    this.eventoService.getEventoWithLang(this.idEvento,this.selectedIdioma.codigo).subscribe((result) => {
      console.log('Noticia result:');
      console.log(result.data);
      this.myEvento=result.data[0];
      console.log('Noticia:');
      this.titulo = result.data[0].titulo;
      this.cuerpo = result.data[0].cuerpo;

      });
  }


  bntUpdateEvento() {
    console.log('Actualizando Evento');
    //Validación de los datos

    if(this.selectedCategoria==null){
      this.alertService.warn(
        this.translateService.instant('eventos.validaciondatos')
      );
      return;
    }
    if(this.fechaInicio>this.fechaFin){
      this.alertService.warn(
        this.translateService.instant('eventos.fechaerror')
      );
      return;
    }

    this.isBusy=true;
    //Actualizar Evento
    this.myEvento.idCategoria = this.selectedCategoria.id;
    this.myEvento.categoria= this.selectedCategoria.nombre;
    this.myEvento.idOrg = this.idOrg;
    this.myEvento.idIdioma = this.selectedIdioma.codigo;
    this.myEvento.titulo = this.titulo;
    this.myEvento.descripcion = this.cuerpo;
    console.log('Configurando Fechas...');
    this.myEvento.fechaInciio = new Date(this.fechaInicio.getFullYear(),this.fechaInicio.getMonth(),this.fechaInicio.getDate(),this.horaInicio.getHours(),this.horaInicio.getMinutes(),this.horaInicio.getSeconds());
    this.myEvento.fechaFin = new Date(this.fechaFin.getFullYear(),this.fechaFin.getMonth(),this.fechaFin.getDate(),this.horaFin.getHours(),this.horaFin.getMinutes(),this.horaFin.getSeconds());
    this.myEvento.mes = this.fechaInicio.getMonth();
    this.myEvento.dia = this.fechaInicio.getDate();
    this.myEvento.diasemana = this.fechaInicio.getDay()+1;
    console.log('Recurrencias...');
    this.myEvento.recurrenciaAnual = this.selectedRecurrencia.id==1;
    this.myEvento.recurrenciaMensual = this.selectedRecurrencia.id==2;
    this.myEvento.recurrenciaSemanal = this.selectedRecurrencia.id==3;
    this.myEvento.recurrenciaDiario = this.selectedRecurrencia.id==4;

    if(this.filePath!=this.myEvento.fotoUrl){
      console.log('Obteniendo en BASE64...');
      this.myEvento.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myEvento.updateFoto=true;
    }


    //Actualizamos Evento
    this.eventoService.actualizarEvento(this.myEvento)
    .subscribe((result) => {
      if(result.error){
        this.alertService.error(
          this.translateService.instant('eventos.errorupdate')
        );
        this.isBusy=false;
      }
      else{
        this.alertService.success(
          this.translateService.instant('eventos.successupdate')
        );
        this.isBusy=false;
      }
    });

  }


  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['eventos']);
  }

}

