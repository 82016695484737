import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';

@Component({
  selector: 'app-categorias-publicaciones-editar',
  templateUrl: './categorias-publicaciones-editar.component.html',
})
export class CategoriasPublicacionesEditarComponent implements OnInit {

  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaTraduccionesEnviar: Categoria[] = [];

  selectedIdioma:Idioma;
  lang;
  private translate: TranslateService;
  idOrg: string;
  idCategoria:number=0;
  nombreCategoria:string="";
  myCategoria:Categoria= new Categoria();

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idCategoria = this.route.snapshot.params['idcategoria'];
   }

  ngOnInit(): void {
    this.loadIdiomas2(this.idOrg);
  }

  loadIdiomas2(idOrg:string) {
    this.idiomaService.getIdiomasFromEnte(idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if(this.listaIdiomas.length>0){
        this.selectedIdioma = this.listaIdiomas[0];
        console.log('Cargando Categoria');
        this.publicacionesService.getCategoriaById(this.idCategoria,this.selectedIdioma.codigo).subscribe((result) => {
          console.log(result);
            this.myCategoria = result.data[0];
            this.nombreCategoria = this.myCategoria.nombre;
        });
      }
    });
// this.idiomaService.GetAll().subscribe((result) => {
//   this.listaIdiomasTotales= result.data;
//   this.listaIdiomasTotales.forEach(element => {
//     let tmpCategoria:Categoria= new Categoria();
//     tmpCategoria.idIdioma=element.codigo;
//     tmpCategoria.filled=false;
//     this.listaTraduccionesEnviar.push(tmpCategoria);
//   });
// });
    
  }

  loadCategoria(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Categorias de idioma:' + value.codigo);
    this.publicacionesService.getCategoriaById(this.idCategoria,this.selectedIdioma.codigo).subscribe((result) => {
      console.log(result.data);
      this.myCategoria = result.data[0];
      this.nombreCategoria = this.myCategoria.nombre;
      });
  }

  btnActualizarCategoria(){

    console.log('Actualizando Categoría');
    
    //Actualizar Categoria
    this.myCategoria.idIdioma = this.selectedIdioma.codigo;
    this.myCategoria.nombre = this.nombreCategoria;
    this.myCategoria.id = this.idCategoria;
    this.publicacionesService.actualizarCategoria(this.myCategoria).subscribe((result) => {
        if(!result.error){
          this.alertService.success(
            this.translateService.instant('categorias.successupdate')
          );
        }
        else{
          this.alertService.error(
            this.translateService.instant('categorias.errorupdate')
          );
        }
    });

    console.log(this.listaTraduccionesEnviar);

  }

  btnCancelar(){
    this.router.navigate(['publicaciones-categorias',{idorg:this.idOrg}]);
  }

}
