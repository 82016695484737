import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farmacias',
  templateUrl: './farmacias.component.html',
  styleUrls: ['./farmacias.component.less']
})
export class FarmaciasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
