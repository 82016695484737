import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: [],
})
export class PublicacionesComponent implements OnInit {
  //Listas
  listaEntesAdministrados: Ente[] = [];
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaCategorias: Categoria[] = [];

  //Traducciones
  listaTraduccionesEnviar: NoticiaTraduccion[] = [];
  //Data
  titulo: string = '';
  cuerpo: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  selectedOrg: Ente;
  selectedCategoria: Categoria;
  isProgramada: boolean;
  isMembersOnly: boolean;
  myNoticia: Noticia = new Noticia();
  filePath: string;
  public fecha: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';

  lang;
  private translate: TranslateService;
  idOrg: string;

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      //Id Ente
      this.idOrg = this.route.snapshot.params['idorg'];
      console.log('LLEGA:' + this.idOrg);
      this.listaEntesAdministrados.forEach(element => {
        if(element.id==this.idOrg){
          this.selectedOrg=element;
          this.loadIdiomas2(this.selectedOrg.id);
        }
      });
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new NoticiaTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });
  }

  loadIdiomas(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando idiomas de :' + value.id);
    this.idiomaService.getIdiomasFromEnte(value.id).subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }
  loadIdiomas2(idOrg:string) {
    this.idiomaService.getIdiomasFromEnte(idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }

  loadCategorias(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Categorias de :' + value.codigo);
    
    this.publicacionesService
      .loadCategorias(value.codigo.toString(), this.selectedOrg.id)
      .subscribe((result) => {
        this.listaCategorias = result.data;
      });
  }

  btnCrearPublicacion() {
    console.log('Creando Publicacion');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.selectedCategoria == null || this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: NoticiaTraduccion = new NoticiaTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.titulo = seleccionado.titulo;
        trad.cuerpo = seleccionado.cuerpo;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myNoticia.idCategoria = this.selectedCategoria.id;
    this.myNoticia.categoria = this.selectedCategoria.nombre;
    this.myNoticia.idOrg = this.selectedOrg.id;
    this.myNoticia.activa = true;
    this.myNoticia.isVip = this.isMembersOnly;
    this.myNoticia.programarPublicacion = this.isProgramada;
    if (this.isProgramada) this.myNoticia.fecha = new Date(this.fecha);
    this.myNoticia.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base64

    this.publicacionesService
      .createPublicacion(this.myNoticia)
      .subscribe((result) => {
        if (!result.error) {
          this.publicacionesService
            .addTraduccionesNoticia(this.listaTraduccionesEnviar, result.codigo)
            .subscribe((resultTrads) => {
              if (!resultTrads.error) {
                this.alertService.success(
                  this.translateService.instant('publicaciones.success')
                );
                this.router.navigate(['publicaciones']);
              } else {
                console.log('Error enviando traducciones publicacion');
                this.alertService.error(
                  this.translateService.instant('publicaciones.error')
                );
                return;
              }
            });
        } else {
          this.alertService.error(
            this.translateService.instant('publicaciones.error')
          );
          return;
        }
      });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.titulo.length <= 0 ||
      this.cuerpo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.titulo = this.titulo;
          x.cuerpo = this.cuerpo;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar(){
    this.router.navigate(['publicaciones-lista']);
  }


}
