<ul class="list-group list-group-flush">
<kendo-grid
  [kendoGridBinding]="dataOrganizaciones"
  [sortable]="true"
  kendoGridSelectBy="codigo"
  [navigable]="true"  
  (cellClick)="cellClick($event)"
  [selectedKeys]="seleccionados"
>
  <ng-template kendoGridToolbarTemplate position="top">
    <button
      kendoButton
      (click)="onClickEditar()"
      class="btn mr-1 btn-success btn-sm mr-1"
    >
      {{ "botones.editar" | translate }}
    </button>
    <button
      kendoButton
      (click)="onClickNuevo()"
      class="btn mr-1 btn-primary btn-sm mr-1"
    >
      {{ "botones.nuevo" | translate }}
    </button>
    <button
      kendoButton
      (click)="onClickEliminar(content)"
      class="btn mr-1 btn-danger btn-sm mr-1"
      [disabled]="isDeleting"
    >
      {{ "botones.eliminar" | translate }}
    </button>
  </ng-template>
  <kendo-grid-checkbox-column
    width="5%"
    showSelectAll="true"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    *ngIf="lang == 'es'"
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="nombre"
    title="{{ 'organizaciones.nombre' | translate }}"
  >
  </kendo-grid-column>

  <!--[checked]="dataOrganizaciones.active" disabled-->
  <kendo-grid-checkbox-column
    width="5%"
    title="{{ 'organizaciones.activo' | translate }}"
    disabled
  ></kendo-grid-checkbox-column>
  
</kendo-grid>
</ul>