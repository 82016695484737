import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';

@Component({
  selector: 'app-categorias-publicaciones-crear',
  templateUrl: './categorias-publicaciones-crear.component.html',
})
export class CategoriasPublicacionesCrearComponent implements OnInit {

  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaTraduccionesEnviar: Categoria[] = [];

  selectedIdioma:Idioma;
  lang;
  private translate: TranslateService;
  idOrg: string;

  nombreCategoria:string="";

  constructor(
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('publicaciones.cabecera');
    this.idOrg = this.route.snapshot.params['idorg'];
   }

  ngOnInit(): void {
    this.loadIdiomas2(this.idOrg);
  }

  loadIdiomas2(idOrg:string) {
    this.idiomaService.getIdiomasFromEnte(idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if(this.listaIdiomas.length>0){
        this.selectedIdioma = this.listaIdiomas[0];
      }
    });
this.idiomaService.GetAll().subscribe((result) => {
  this.listaIdiomasTotales= result.data;
  this.listaIdiomasTotales.forEach(element => {
    let tmpCategoria:Categoria= new Categoria();
    tmpCategoria.idIdioma=element.codigo;
    tmpCategoria.filled=false;
    this.listaTraduccionesEnviar.push(tmpCategoria);
  });
});
    
  }

  btnCrearCategoria(){
    if(this.listaTraducciones.length<1){
      this.alertService.info(
        this.translateService.instant('categorias.addtrads')
      );
    }
    console.log('Creando Categoría');
    let selected:Categoria= new Categoria();
    let encontrado:boolean=false;
    this.listaTraduccionesEnviar.forEach(element => {
      if(element.filled && !encontrado){
        selected =element;
        encontrado=true;
      }
    });

    this.listaTraduccionesEnviar.forEach(element => {
      if(!element.filled){
        element.nombre=selected.nombre;
      }
    });
    
    //Crear Categoria

    this.publicacionesService.crearCategoria(this.listaTraduccionesEnviar,this.idOrg).subscribe((result) => {
        if(!result.error){
          this.alertService.success(
            this.translateService.instant('categorias.success')
          );
        }
        else{
          this.alertService.error(
            this.translateService.instant('categorias.error')
          );
        }
    });

    console.log(this.listaTraduccionesEnviar);

  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreCategoria.length <= 0 
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreCategoria;
        }
      });
    } else {
             this.alertService.info(
          this.translateService.instant('categorias.alreadyadded')
        );
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  btnCancelar(){
    this.router.navigate(['publicaciones-categorias',{idorg:this.idOrg}]);
  }




}
