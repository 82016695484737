<div class="row">
    <div class="col-lg-4">
      <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "publicaciones.titulo_update" | translate }}</h3>
      </div>
      <div class="card-body">

        <button
        type="button"
        class="btn btn-info"
        (click)="btnNavigateSorteos()"
      >
        {{ "publicaciones.sorteos" | translate }}
      </button>

        <!--Lista idiomas-->
        <div class="clearfix">
          <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
          </kendo-label>
      </div>
        <kendo-dropdownlist #dropdownI
        [data]="listaIdiomas"
        textField="nombre"
        valueField="codigo"
        [(ngModel)]="selectedIdioma"
        (valueChange)="loadNoticia($event, dropdownI)"
        ></kendo-dropdownlist>
        <!--Titulo-->
        <kendo-textbox
          placeholder="{{ 'publicaciones.titulo_publi' | translate }}"
          [clearButton]="true"
          [(ngModel)]="titulo"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <button kendoButton look="clear" icon="calendar"></button>
          </ng-template>
        </kendo-textbox>
        <!--Cuerpo-->
        <div class="form-group">
            <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
                <textarea kendoTextArea style="resize: none" rows="5" [(ngModel)]="cuerpo"> </textarea>
              </kendo-label>
          </div>
          <!--Lista Categorias-->
          <div class="form-group">
          <kendo-dropdownlist 
          [data]="listaCategorias"
          textField="nombre"
          valueField="id"
          [(ngModel)]="selectedCategoria"
          ></kendo-dropdownlist>
          </div>
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
              <span>Select Image</span>
              <input #imageInput
                     type="file"
                     accept="image/*"
                     (change)="imagePreview($event)">
            </label>
            
            <div *ngIf="filePath && filePath !== ''">
              <img [src]="filePath" style="height: 200px;">
            </div>
          <!-- Publicacion Programada-->
          <div>
        
                  <kendo-label
                  class="k-checkbox-label"
                  text="{{ 'publicaciones.programada' | translate }}"
                ></kendo-label>
                <input type="checkbox"
                [(ngModel)]="isProgramada"
                kendoCheckBox />
  
                    <kendo-datetimepicker *ngIf="isProgramada" [format]="format" [(ngModel)]="fecha">
                    </kendo-datetimepicker>
  
         
          </div>
          <!--CheckBox Miembros-->
          <div>
              <kendo-label
              class="k-checkbox-label"
              text="{{ 'publicaciones.miembros' | translate }}"
            ></kendo-label>
            <input type="checkbox"
            [(ngModel)]="isMembersOnly"
            kendoCheckBox />
      </div>
  
        <!--Boton Publicar-->
        <button
          type="button"
          class="btn btn-success"
          (click)="btnActualizarPublicacion()"
        >
          {{ "publicaciones.actualizar" | translate }}
        </button>
        <button type="button"
        class="btn btn-danger btn-sm mr-1"
        (click)="btnCancelar()">
        {{ "botones.cancelar" | translate }}
      </button>
      </div>
    </div>
  </div>
  </div>
  