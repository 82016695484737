import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { EncuestasService } from "@app/_services/encuestas.service";

@Component({
  selector: 'app-encuestas',
  templateUrl: './encuestas.component.html',
})
export class EncuestasComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedOrg: Ente;
  listaPublicaciones: Noticia[] = [];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private encuestasService: EncuestasService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('eventos.cabecera');
    this.encuestasService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg=this.listaEntesAdministrados[0];
        this.encuestasService
        .getEncuestasByEnte(this.selectedOrg.id,1)
        .subscribe((result) => {
          console.log(result);
          this.listaPublicaciones = result.data;
          console.log(
            'Encuestas cargadas: ' + this.listaPublicaciones.length
          );
        });
      }
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  loadPublicaciones(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando eventos de :' + value.id);
    this.encuestasService
      .getEncuestasByEnte(this.selectedOrg.id,1)
      .subscribe((result) => {
        console.log(result);
        this.listaPublicaciones = result.data;
        console.log(
          'Encuestas cargadas: ' + this.listaPublicaciones.length
        );
      });
  }

  onClickNuevo() {
    if (this.selectedOrg == null) {
      this.alertService.warn(
        this.translateService.instant('publicaciones.selectente')
        );
    } else {
      this.router.navigate(['encuestas-crear',{ idorg:this.selectedOrg.id }]);
    }
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    this.router.navigate(['encuestas-editar',{idorg:this.selectedOrg.id, idencuesta:this.seleccionados[0] }]);
  }

  cellClick(){
    this.router.navigate(['encuestas-editar',{idorg:this.selectedOrg.id, idencuesta:this.seleccionados[0] }]);
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.encuestasService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.encuestasService.getEncuestasByEnte(this.selectedOrg.id,1).subscribe((result) => {
            this.listaPublicaciones = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickCategorias(){
    this.router.navigate(['encuestas-categorias',{idorg:this.selectedOrg.id }]);
  }
}
