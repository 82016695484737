import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-farmacias-editar',
  templateUrl: './farmacias-editar.component.html',
  styleUrls: ['./farmacias-editar.component.less']
})
export class FarmaciasEditarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
