import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import {
  Evento,
  EventoModel,
  EventoTraduccion,
  Recurrencia,
} from '@app/_models/evento';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';

@Component({
  selector: 'app-eventos-crear',
  templateUrl: './eventos-crear.component.html',
})
export class EventosCrearComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  listaCategorias: Categoria[] = [];
  listaRecurrencias: Recurrencia[] = [];

  //Traducciones
  listaTraduccionesEnviar: EventoTraduccion[] = [];
  //Data
  titulo: string = '';
  cuerpo: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  selectedIdiomaPrueba: Idioma;
  selectedCategoria: Categoria = new Categoria();
  selectedRecurrencia: Recurrencia = new Recurrencia();
  isAllDay: boolean;
  isRecurrente: boolean;

  myEvento: Evento = new Evento();
  myModel: EventoModel = new EventoModel();
  filePath: string;
  public fechaInicio: Date = new Date();
  public fechaFin: Date = new Date();
  public horaInicio: Date = new Date();
  public horaFin: Date = new Date();
  public format = 'dd/MM/yyyy HH:mm';
  idOrg: string;
  lang;
  private translate: TranslateService;

  constructor(
    private eventoService: EventosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('eventos.cabecera');
    this.idOrg = this.route.snapshot.params['idorg'];
    this.loadIdiomas();
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new EventoTraduccion();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });
    //Get Recurrencias
    this.listaRecurrencias=this.eventoService.getRecurrencias();
  }

  loadIdiomas() {
    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
    });
  }

  loadCategorias(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Categorias de :' + value.codigo);
    this.eventoService
      .loadCategorias(value.codigo.toString(), this.idOrg)
      .subscribe((result) => {
        this.listaCategorias = result.data;
      });
  }

  btnCrearEvento() {
    console.log('Creando Evento');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.warn(
        this.translateService.instant('eventos.errortrads')
      );
      return;
    }
    if (this.selectedCategoria == null ) {
      this.alertService.warn(
        this.translateService.instant('eventos.validaciondatos')
      );
      return;
    }
    if (this.fechaInicio > this.fechaFin) {
      console.log('F.Inicio');
      console.log(this.fechaInicio);
      console.log('F.Fin');
      console.log(this.fechaFin);
      this.alertService.warn(
        this.translateService.instant('eventos.fechaerror')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: EventoTraduccion = new EventoTraduccion();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.titulo = seleccionado.titulo;
        trad.cuerpo = seleccionado.cuerpo;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myEvento.idCategoria = this.selectedCategoria.id;
    this.myEvento.categoria = this.selectedCategoria.nombre;
    this.myEvento.idOrg = this.idOrg;
    console.log('Configurando Fechas...');
    this.myEvento.fechaInciio = new Date(
      this.fechaInicio.getFullYear(),
      this.fechaInicio.getMonth(),
      this.fechaInicio.getDate(),
      this.horaInicio.getHours(),
      this.horaInicio.getMinutes(),
      this.horaInicio.getSeconds()
    );
    this.myEvento.fechaFin = new Date(
      this.fechaFin.getFullYear(),
      this.fechaFin.getMonth(),
      this.fechaFin.getDate(),
      this.horaFin.getHours(),
      this.horaFin.getMinutes(),
      this.horaFin.getSeconds()
    );

    this.myEvento.mes = this.fechaInicio.getMonth();
    this.myEvento.dia = this.fechaInicio.getDate();
    this.myEvento.diasemana = this.fechaInicio.getDay() + 1;
    console.log('Recurrencias...');
    console.log(this.selectedRecurrencia);
    this.myEvento.recurrenciaAnual = this.selectedRecurrencia.id == 1;
    this.myEvento.recurrenciaMensual = this.selectedRecurrencia.id == 2;
    this.myEvento.recurrenciaSemanal = this.selectedRecurrencia.id == 3;
    this.myEvento.recurrenciaDiario = this.selectedRecurrencia.id == 4;
    this.myEvento.fotoUrl = this.filePath.split(',')[1]; //Borramos cabecera data: base64

    this.myModel.evento = this.myEvento;
    this.myModel.listaTraducciones = this.listaTraduccionesEnviar;
    this.eventoService.crearEvento(this.myModel).subscribe((result) => {
      if (!result.error) {
        // this.eventoService
        //   .addTraduccionesEvento(this.listaTraduccionesEnviar, result.codigo)
        //   .subscribe((resultTrads) => {
        //     console.log(resultTrads);
        //     if (!resultTrads.error) {
        //       this.alertService.success(
        //         this.translateService.instant('eventos.success')
        //       );
        //       this.router.navigate(['eventos']);
        //       return;
        //     } else {
        //       this.alertService.error(
        //         this.translateService.instant('eventos.error')
        //       );
        //       return;
        //     }
        //   });

       this.alertService.success(
      this.translateService.instant('eventos.success')
       );
      } else {
        this.alertService.error(this.translateService.instant('eventos.error'));
        return;
      }
    });

  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.titulo.length <= 0 ||
      this.cuerpo.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('eventos.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.titulo = this.titulo;
          x.cuerpo = this.cuerpo;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  // changedRecurrencia(recurrencia:Recurrencia){
  //   console.log('Cambia recurrencia a: ');
  //   console.log(recurrencia);
  //   this.selectedRecurrencia = recurrencia;
  //   console.log('END ChangedRecurrenia');
  // }

  getIdiomaFromReus(idioma) {
    //console.log('Llega Idioma');
    //console.log(idioma);
    this.selectedIdioma = idioma;

    //Categorias
    this.eventoService
      .loadCategorias(this.selectedIdioma.codigo.toString(), this.idOrg)
      .subscribe((result) => {
        this.listaCategorias = result.data;
      });
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }


  btnCancelar(){
    this.router.navigate(['eventos']);
  }
}
