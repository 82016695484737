import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';


@Component({
  selector: 'app-categorias-publicaciones',
  templateUrl: './categorias-publicaciones.component.html',
})
export class CategoriasPublicacionesComponent implements OnInit {

  listaCategorias:Categoria[]=[];
  public seleccionados: number[] = [];
  idOrg:string;
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(   
    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal)
     {
      this.translate = translate;
      this.idOrg = this.route.snapshot.params['idorg']
      this.menuService.titulo = this.translate.instant('publicaciones.categorias');
      this.publicacionesService.loadCategorias("1",this.idOrg).subscribe((result) => {
        console.log('Categorías cargadas');
        console.log(result.data);
        this.listaCategorias = result.data;
      });
     }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  onClickNuevo() {

      this.router.navigate(['publicaciones-categorias-crear',{ idorg:this.idOrg }]);
    
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar(){
    this.router.navigate(['publicaciones-categorias-editar',{idorg:this.idOrg, idcategoria:this.seleccionados[0] }]);
  }

  cellClick(){
    this.router.navigate(['publicaciones-categorias-editar',{idorg:this.idOrg, idcategoria:this.seleccionados[0] }]);
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.publicacionesService.deleteCategorias({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.publicacionesService.loadCategorias("1",this.idOrg).subscribe((result) => {
            this.listaCategorias = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
