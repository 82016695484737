import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
})
export class PruebaComponent implements OnInit {
  public dataOrganizaciones: [];
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;


  constructor(
    private organizacionesService: OrganizacionesService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
  ) {
    this.translate = translate;
    this.organizacionesService.GetAll().subscribe((result) => {
      this.dataOrganizaciones = result.data;
    })
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.organizacionesService.GetAll().subscribe((result) => {
      this.dataOrganizaciones = result.data;
      console.log('dataOrganizaciones Loaded :'+this.dataOrganizaciones.length.toString())
    });
  }

  onClickNuevo() {
   console.log('añadir nuevo click')
  }

  onClickEditar() {
    console.log(`Editar click`);
  }

  cellClick(e) {
    console.log('click row');
  }

  onClickEliminar(content) {
    console.log('eliminar click');
  }
}
