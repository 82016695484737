import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { stringify } from '@angular/compiler/src/util';
import { Recinto } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { EcommerceService } from '@app/_services/ecommerce.service';
import { Articulo, CategoriaEcommerce } from '@app/_models/ecommerce';

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
})
export class ArticulosComponent implements OnInit {
  listaEntesAdministrados: Ente[] = [];
  selectedOrg: Ente;
  selectedCategoria: CategoriaEcommerce;
  listaArticulos: Articulo[] = [];
  listaCategoriasEcommerce: CategoriaEcommerce[] = [];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private ecommerceService: EcommerceService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant(
      'ecommerce.cabeceraarticulos'
    );
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    this.ecommerceService.getEntesAdministrados().subscribe((resultEntes) => {
      this.listaEntesAdministrados = resultEntes.data;
      if (this.listaEntesAdministrados.length > 0) {
        this.selectedOrg = this.listaEntesAdministrados[0];
        this.ecommerceService
          .getCategoriasFromEcommerceEnte(this.selectedOrg.id)
          .subscribe((resultCategorias) => {
            console.log(resultCategorias.data);
            this.listaCategoriasEcommerce = resultCategorias.data;
            if (this.listaCategoriasEcommerce.length > 0) {
              this.selectedCategoria = this.listaCategoriasEcommerce[0];
              this.ecommerceService
                .getArticulosFromCategoria(this.selectedCategoria.id)
                .subscribe((result) => {
                  console.log(result);
                  this.listaArticulos = result.data;
                });
            }
          });
      }
    });
  }

  loadCategoriasEcommerce(value, dropDownControl: DropDownListComponent) {
    console.log('Cargando categorias de :' + value.id);
    this.ecommerceService
      .getCategoriasFromEcommerceEnte(this.selectedOrg.id)
      .subscribe((result) => {
        console.log(result);
        this.listaCategoriasEcommerce = result.data;
        if (this.listaCategoriasEcommerce.length <= 0) {
          this.selectedCategoria=null;
        }
        else{
          this.selectedCategoria= this.listaCategoriasEcommerce[0];
        }
      });
  }

  loadArticulos(value, dropDownControl: DropDownListComponent) {
    console.log('Cargando articulos de :' + value.id);
    this.ecommerceService
      .getArticulosFromCategoria(this.selectedCategoria.id)
      .subscribe((result) => {
        console.log(result);
        this.listaArticulos = result.data;
      });
  }

  onClickNuevo() {
    
    if (this.selectedOrg == null) {
      this.alertService.warn(
        this.translateService.instant('publicaciones.selectente')
      );
    }
      else if(this.selectedCategoria==null){
        this.alertService.warn(
          this.translateService.instant('ecommerce.creacategoriaprimero')
        );
      }
     else {
      this.router.navigate([
        'ecommerce-articulos-crear',
        { idorg: this.selectedOrg.id, idcategoria: this.selectedCategoria.id },
      ]);
    }
  }

  onClickEliminar(content) {
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, {
        backdrop: 'static',
        size: 'lg',
        keyboard: false,
        centered: true,
      });
    }
  }

  onClickEditar() {
    this.router.navigate([
      'ecommerce-articulos-editar',
      { idorg: this.selectedOrg.id, idarticulo: this.seleccionados[0] },
    ]);
  }

  cellClick() {
    this.router.navigate([
      'ecommerce-articulos-editar',
      { idorg: this.selectedOrg.id, idarticulo: this.seleccionados[0] },
    ]);
  }

  eliminarRegistro(contentloading) {
    console.log('Eliminar registro');
    this.ecommerceService
      .delete({ codigos: this.seleccionados })
      .subscribe((data) => {
        if (data.error == false) {
          this.ecommerceService
            .getCategoriasFromEcommerceEnte(this.selectedOrg.id)
            .subscribe((result) => {
              this.listaCategoriasEcommerce = result.data;
            });
        }
        this.modalReferenceloading.close();
      });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, {
      backdrop: 'static',
      size: 'sm',
      keyboard: false,
      centered: true,
    });
    this.seleccionados = [];
  }
}
