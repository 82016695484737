export class Noticia {
    id: string;
    titulo: string;
    cuerpo: string;
    categoria:string;
    author:string;
    fotoUrl:string;
    fecha:Date;
    likes:number;
    idOrg:string;
    activa:boolean;
    privada:boolean;
    numcomentarops:number;
    idCategoria:number;
    created:Date;
    idIdioma:number;
    isVip:boolean;
    programarPublicacion:boolean;
    sorteoGanador:number;
    updateFoto:boolean;
}

export class NoticiaTraduccion {
    id: string;
    titulo: string;
    cuerpo: string;
    idIdioma:number;
    nombreIdioma:string;
    idNoticia:number;
    filled:boolean;
}
