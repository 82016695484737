import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { stringify } from '@angular/compiler/src/util';
import { Usuario } from '@app/_models';
@Component({
  selector: 'app-sorteo',
  templateUrl: './sorteo.component.html',
})
export class SorteoComponent implements OnInit {

  listaUsuarios:Usuario[]=[];
  listaUsuariosTMP:Usuario[]=[];
  listaGanadores:Usuario[]=[];
  numeroGanadores:number=1;
  lang;
  private translate: TranslateService;
  idNoticia:number;

  constructor(    private publicacionesService: PublicacionesService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService) { 
      this.translate = translate;
      this.menuService.titulo = this.translate.instant('publicaciones.cabecera_sorteo');
    }

  ngOnInit(): void {

    this.idNoticia = this.route.snapshot.params['idnoticia']
    this.publicacionesService.getUsuariosLikedPublicacion(this.idNoticia).subscribe((result)=>{
      this.listaUsuarios=result.data;
      this.listaUsuariosTMP=result.data;
    });

    this.publicacionesService.getGanadores(this.idNoticia).subscribe((result)=>{
      this.listaGanadores = result.data;
      if(this.listaGanadores.length>0)
      this.numeroGanadores = this.listaGanadores.length;
    });
  }

  btnObtenerUsuario(){

    console.log('Limpiando datos');
    this.listaGanadores=[];
    this.listaUsuariosTMP = this.listaUsuarios.map(x => Object.assign({}, x));
  
    console.log('Comprobacion users> ganadores');
    if(this.listaUsuarios.length< this.numeroGanadores)
    this.numeroGanadores=this.listaUsuarios.length;

    console.log('Obteniendo ganadores');
    for (let i = 0; i < this.numeroGanadores; i++) {
      let ganador:Usuario = this.listaUsuariosTMP[Math.floor(Math.random() * this.listaUsuariosTMP.length)];
      this.listaUsuariosTMP = this.listaUsuariosTMP.filter((x)=>x.id!=ganador.id);
      this.listaGanadores.push(ganador);
    }
  }

  btnAsignarGanador(){
    console.log('Asignando Ganadores...');
    this.publicacionesService.asignarGanadoresSorteoNoticia(this.listaGanadores,this.idNoticia).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('publicaciones.successasignar')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('publicaciones.errorasignar')
        );
      }
    });
  }

}
