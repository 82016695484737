import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EcommerceService } from '@app/_services/ecommerce.service';
import {
  CategoriaEcommerce,
  CategoriaEcommerceTrad,
} from '@app/_models/ecommerce';

@Component({
  selector: 'app-categorias-articulos-editar',
  templateUrl: './categorias-articulos-editar.component.html',
})
export class CategoriasArticulosEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];

  //Traducciones
  listaTraduccionesEnviar: CategoriaEcommerceTrad[] = [];
  //Data
  nombreRecinto: string = '';
  descripcionRecinto: string = '';
  colorCategoria:string;
  superficieRecinto: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  categoriaActiva: boolean = true;
  isRedirect:boolean=false;
  urlRedirect:string='';
  myCategoria: CategoriaEcommerce = new CategoriaEcommerce();
  filePath: string;

  idCategoriaEcommerce:number;
  lang;
  private translate: TranslateService;
  idOrg: string;

  constructor(
    private ecommerceService: EcommerceService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('ecommerce.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idCategoriaEcommerce = this.route.snapshot.params['idcategoria'];

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.ecommerceService
        .getCategorialang(this.idCategoriaEcommerce, this.selectedIdioma.codigo)
        .subscribe((result) => {
          // console.log('Categoria cargada: ');
          // console.log(result);
          this.myCategoria = result.data[0];
          this.nombreRecinto = result.data[0].nombre;
          this.descripcionRecinto = result.data[0].descripcion;
          this.categoriaActiva = result.data[0].activa;
          this.colorCategoria = result.data[0].color;
          this.filePath= result.data[0].urlFoto;
          this.isRedirect = result.data[0].isRedirect;
          this.urlRedirect = result.data[0].urlRedirect;
        });
      }
    });
  }

  btnEditarCategoria(){
    console.log('Actualizando categoria');
    if (this.nombreRecinto==null || this.nombreRecinto.length < 1 || this.descripcionRecinto==null || this.descripcionRecinto.length < 1) {
      this.alertService.info(
        this.translateService.instant('recintos.validacion')
      );
      return;
    }
    if(this.filePath!=this.myCategoria.urlFoto){
      console.log('Obteniendo en BASE64...');
      this.myCategoria.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myCategoria.updateFoto=true;
    }
    this.myCategoria.color = this.colorCategoria;
    this.myCategoria.nombre = this.nombreRecinto;
    this.myCategoria.descripcion = this.descripcionRecinto;
    this.myCategoria.activa = this.categoriaActiva;
    this.myCategoria.idIdioma = this.selectedIdioma.codigo;
    this.myCategoria.isRedirect = this.isRedirect;
    this.myCategoria.urlRedirect = this.urlRedirect;
    this.ecommerceService.actualizarCategoriaEcommerce(this.myCategoria).subscribe((resultUpdate)=>{
      if(!resultUpdate.error){
        this.alertService.success(
          this.translateService.instant('ecommerce.successupdate')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('ecommerce.errorupdate')
        );
      }
    });

  }

  loadCategoriaEcommerce(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Recinto Idioma :' + value.codigo);
    this.ecommerceService
      .getCategorialang(this.idCategoriaEcommerce, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log('Categoria cargada: ');
        console.log(result);
        this.nombreRecinto = result.data[0].nombre;
        this.descripcionRecinto = result.data[0].descripcion;
      });
  }


    //Imagen

    imagePreview(e) {
      const file = (e.target as HTMLInputElement).files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.filePath = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  
    btnCancelar() {
      this.router.navigate(['ecommerce-categorias']);
    }
}
