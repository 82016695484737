import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '@app/_helpers';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/usuariostipos`;

@Injectable({
  providedIn: 'root'
})
export class UsuariosTiposService {

  constructor(private http: HttpClient) {
  }

  create(params) {
    return this.http.post(baseUrl, params);
  }

  update(params) {
    return this.http.post(baseUrl + "/update", params);
  }

  getAll() {
    return this.http.get<TipoUsuario[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<TipoUsuario>(`${baseUrl}/${id}`);
  }

  delete(id: number) {
    return this.http.post(baseUrl + "/delete", {id});
  }

}
