import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Contacto } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { CategoriaEcommerce, Articulo } from '@app/_models/ecommerce';

const baseUrl = `${environment.apiUrl}/ecommerce`;

@Injectable()
export class EcommerceService {
  constructor(private http: HttpClient) {}

  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    );
  }
  //CATEGORIAS
  public getCategoriasFromEcommerceEnte(idOrg: string): Observable<any> {
    console.log('[S] Obtenidno Categorias todas]');
    return this.http.get(baseUrl + '/' + idOrg);
  }

  public getCategoria(idCategoria: number): Observable<any> {
    console.log('[S] Obtenidno Categoria]');
    return this.http.get(baseUrl + '/categoria/' + idCategoria);
  }

  public getCategorialang(idCategoria: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/categoria/' + idCategoria + '/' + lang);
  }

  public crearCategoriaEcommerce(
    categoria: CategoriaEcommerce
  ): Observable<any> {
    console.log('[S] Categoria crear');
    console.log(categoria);
    return this.http.post(baseUrl + '/create/', JSON.stringify(categoria), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public actualizarCategoriaEcommerce(
    categoria: CategoriaEcommerce
  ): Observable<any> {
    console.log(categoria);
    return this.http.post(baseUrl + '/update/', JSON.stringify(categoria), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //ARTICULOS

  public getArticulosFromCategoria(idCategoria: number): Observable<any> {
    console.log('[S] Obtenidno articulos categoria] ' + idCategoria);
    return this.http.get(baseUrl + '/articulos/' + idCategoria);
  }

  public getArticulo(idArticulo: number): Observable<any> {
    console.log('[S] Obtenidno Categoria]');
    return this.http.get(baseUrl + '/articulo/' + idArticulo);
  }

  public getArticuloLang(idArticulo: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/articulo/' + idArticulo + '/' + lang);
  }

  public crearArticulo(articulo: Articulo): Observable<any> {
    console.log('[S] Categoria crear');
    console.log(articulo);
    return this.http.post(
      baseUrl + '/createarticulo/',
      JSON.stringify(articulo),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public actualizarArtículo(articulo: Articulo): Observable<any> {
    console.log(articulo);
    return this.http.post(
      baseUrl + '/updatearticulo/',
      JSON.stringify(articulo),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteArticulos(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminararticulos', formData, {
      withCredentials: true,
    });
  }

  public getImagesFromArticulo(idArticulo: number): Observable<any> {
    return this.http.get(baseUrl + '/articulo/imagenes/' + idArticulo);
  }

  public deleteImagen(idImagen: number): Observable<any> {
    return this.http.get(baseUrl + '/deleteimagen/' + idImagen);
  }

  
}
